import i18next from 'i18next';

import { LANGUAGE_KEYS } from '~constants/languages';

i18next.addResources(LANGUAGE_KEYS.CHILE, 'CartContentModal', {
  yourOrder: 'Tu pedido',
  goToCheckout: 'Continuar al pago',
  goToQuickOrder: 'Compra con 1 click',
  backToMenu: 'Explora el menú ahora',
  addedProduct: 'Producto agregado',
  subtotal: 'Subtotal'
});

i18next.addResources(LANGUAGE_KEYS.PORTUGAL, 'CartContentModal', {
  yourOrder: 'O Meu Carrinho',
  goToCheckout: 'Continuar a pagar',
  goToQuickOrder: 'Compre com 1 clique',
  backToMenu: 'Faça o seu pedido',
  addedProduct: 'Produto adicionado'
});

i18next.addResources(LANGUAGE_KEYS.SPAIN, 'CartContentModal', {
  yourOrder: 'Mi Carrito',
  goToQuickOrder: 'Compra con 1 clic'
});
