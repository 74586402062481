import i18next from 'i18next';

i18next.addResources('es_CL', 'CartRedeemCoupon', {
  cta: '¿Tienes un código promocional?',
  login: 'Inicia sesión para usar tu código',
  title: '¿Tienes un código promocional?',
  description:
    'Introduce tu código promocional o tarjeta de regalo a continuación para disfrutar de nuestras deliciosas ofertas.',
  coupon: 'Cupón "{{coupon}}"'
});

i18next.addResources('pt_PT', 'CartRedeemCoupon', {
  cta: 'Tens um cupão ou Gift Card?',
  login: 'Inicia sessão para usar o teu código',
  title: 'Tens um cupão ou Gift Card?',
  description:
    'Introduz o teu código promocional ou cartão de oferta abaixo para desfrutar das nossas deliciosas promoções.',
  coupon: 'Cupão "{{coupon}}"'
});
