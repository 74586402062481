/* eslint-disable max-lines */
import { t } from 'i18next';

import { TRANSLATION_KEY, PUBLIC_CDN } from './environment';
import { COUNTRIES } from './languages';
import { PATHS } from './paths';

export const CALL_CENTER_PAGES = {
  LOGIN: {
    exact: true,
    noNavbar: true,
    noFooter: true,
    path: PATHS.home.default,
    title: t('CallCenterLogin:pageTitle'),
    description: t('CallCenterLogin:pageDescription')
  },
  NEW_ORDER: {
    exact: true,
    noCartFooter: false,
    noFooter: true,
    isCallcenter: true,
    path: '/nueva-orden',
    title: t('CallCenterNewOrder:pageTitle'),
    description: t('CallCenterNewOrder:pageDescription')
  },
  MENU: {
    exact: true,
    noCartFooter: false,
    noFooter: true,
    isCallcenter: true,
    path: '/menu',
    title: t('CallCenterNewOrder:pageTitle'),
    description: t('CallCenterNewOrder:pageDescription')
  },
  CHECKOUT: {
    exact: true,
    noCartFooter: true,
    noFooter: true,
    isCallcenter: true,
    path: '/checkout',
    title: t('CallCenterNewOrder:pageTitle'),
    description: t('CallCenterNewOrder:pageDescription')
  },
  ORDER_BUILDER: {
    exact: true,
    path: ['/order-builder/:offerId', '/order-builder/:offerId/edit/:editingId'],
    basePath: '/order-builder',
    title: t('Home:pageTitle'),
    description: t('Home:pageDescription'),
    noCartFooter: false,
    isCallcenter: true
  }
};

export const CLIENT_PAGES = {
  LANDING: {
    noContainer: true,
    path: '/landing',
    title: t('Landing:pageTitle'),
    description: t('Landing:pageDescription')
  },
  RECOVER_PASSWORD: {
    exact: true,
    path: '/recuperar-contrasena'
  },
  PAPA_POINTS: {
    exact: true,
    noCartFooter: false,
    path: '/papa-puntos',
    title: t('PapaPoints:pageTitle'),
    description: t('PapaPoints:pageDescription')
  },
  INDEX: {
    exact: true,
    noCartFooter: false,
    path: '/index.html',
    title: t('Home:pageTitle'),
    description: t('Home:pageDescription'),
    keywords: t('Home:keywords')
  },
  HOME: {
    exact: true,
    noCartFooter: false,
    path: PATHS.home.default,
    title: t('Home:pageTitle'),
    description: t('Home:pageDescription'),
    keywords: t('Home:keywords')
  },
  ORDER: {
    exact: true,
    path: t('Order:pagePath'),
    title: t('Order:pageTitle'),
    description: t('Order:pageDescription')
  },
  CHECKOUT: {
    exact: true,
    noNavbar: true,
    noFooter: true,
    path: '/checkout',
    title: t('Checkout:pageTitle'),
    description: t('Checkout:pageDescription')
  },
  PURCHASE_CONTROL: {
    exact: true,
    noNavbar: true,
    noFooter: true,
    path: t('PurchaseControl:pagePath'),
    title: t('PurchaseControl:pageTitle'),
    description: t('PurchaseControl:pageDescription')
  },
  PURCHASE_OK: {
    exact: true,
    noNavbar: true,
    noFooter: true,
    path: t('PurchaseOK:pagePath'),
    title: t('PurchaseOK:pageTitle'),
    description: t('PurchaseOK:pageDescription')
  },
  PURCHASE_FAIL: {
    exact: true,
    noNavbar: true,
    noFooter: true,
    path: t('PurchaseFail:pagePath'),
    title: t('PurchaseFail:pageTitle'),
    description: t('PurchaseFail:pageDescription')
  },
  PRIVACY_POLICY: {
    exact: true,
    path: t('PrivacyPolicy:pagePath'),
    title: t('PrivacyPolicy:pageTitle'),
    description: t('PrivacyPolicy:pageDescription')
  },
  COMPLAINTS_BOOK: {
    exact: true,
    path: t('ComplaintsBook:pagePath'),
    title: t('ComplaintsBook:pageTitle'),
    description: t('ComplaintsBook:pageDescription')
  },
  TERMS: {
    exact: true,
    path: t('TermsAndConditions:pagePath'),
    title: t('TermsAndConditions:pageTitle'),
    description: t('TermsAndConditions:pageDescription')
  },
  OFFICIAL_STATEMENT: {
    exact: true,
    path: t('OfficialStatement:pagePath'),
    title: t('OfficialStatement:pageTitle'),
    description: t('OfficialStatement:pageDescription')
  },
  PROFILE: {
    exact: true,
    path: '/perfil',
    title: t('Profile:pageTitle'),
    description: t('Profile:pageDescription')
  },
  STORES: {
    exact: true,
    path: t('StoresLocation:pagePath'),
    title: t('StoresLocation:pageTitle'),
    description: t('StoresLocation:pageDescription')
  },
  NPS: {
    exact: true,
    noNavbar: true,
    noContainer: true,
    noFooter: true,
    path: '/nps'
  },
  HELPCENTER: {
    exact: true,
    path: t('HelpCenter:pagePath'),
    title: t('HelpCenter:pageTitle'),
    description: t('HelpCenter:pageDescription')
  },
  ABOUT_US: {
    exact: true,
    path: t('AboutUs:pagePath'),
    title: t('AboutUs:pageTitle'),
    description: t('AboutUs:pageDescription'),
    keywords: t('AboutUs:keywords')
  },
  WORK_WITH_US: {
    exact: true,
    path: t('WorkWithUs:pagePath'),
    title: t('WorkWithUs:pageTitle'),
    description: t('WorkWithUs:pageDescription')
  },
  INGREDIENTS: {
    exact: true,
    path: '/ingredientes',
    title: t('Ingredients:pageTitle'),
    description: t('Ingredients:pageDescription'),
    keywords: t('Ingredients:keywords')
  },
  CONTACT_FORM: {
    exact: true,
    path: '/contacto',
    title: t('Contact:pageTitle'),
    description: t('Contact:pageDescription'),
    keywords: t('Contact:keywords')
  },
  COOKIES_POLICY: {
    exact: true,
    path: '/politica-cookies',
    title: t('CookiesPolicy:pageTitle'),
    description: t('CookiesPolicy:pageDescription')
  },
  FRANCHISES: {
    exact: true,
    path: t('Franchises:pagePath'),
    title: t('Franchises:pageTitle'),
    description: t('Franchises:pageDescription'),
    keywords: t('Franchises:keywords')
  },
  FRANCHISES_INFORMATION: {
    exact: true,
    path: t('FranchisesInformation:pagePath'),
    title: t('FranchisesInformation:pageTitle'),
    description: t('FranchisesInformation:pageDescription'),
    keywords: t('Franchises:keywords')
  },
  FRANCHISES_CONTACT_FORM: {
    exact: true,
    path: t('FranchisesContactForm:pagePath'),
    title: t('FranchisesContactForm:pageTitle'),
    description: t('FranchisesContactForm:pageDescription')
  },
  FREE_CHICKENS: {
    exact: true,
    path: '/comunicado-gallinas-libres',
    title: t('FreeChickens:pageTitle'),
    description: t('FreeChickens:pageDescription')
  },
  UNAVAILABLE: {
    exact: true,
    path: '/no-disponible',
    noNavbar: true,
    noContainer: true,
    noFooter: true
  },
  LEGAL_DISCLAIMER: {
    exact: true,
    path: '/aviso-legal',
    title: "Aviso legal | Papa John's España"
  },
  ORDER_BUILDER: {
    exact: true,
    path: ['/order-builder/:offerId', '/order-builder/:offerId/edit/:editingId'],
    basePath: '/order-builder',
    title: t('Home:pageTitle'),
    description: t('Home:pageDescription'),
    noCartFooter: false
  },
  NOT_FOUND: {
    noContainer: true,
    path: '',
    title: t('Landing:pageTitle'),
    description: t('Landing:pageDescription')
  },
  GIFT_CARD: {
    exact: true,
    path: '/gift-card',
    title: t('GiftCards:pageTitle', { country: COUNTRIES[TRANSLATION_KEY] }),
    noCartFooter: false
  },
  STORE: {
    exact: true,
    basePath: t('StoresLocation:pagePath'),
    path: `${t('StoresLocation:pagePath')}/:storePath`,
    title: t('StoresLocation:pageTitle'),
    description: t('StoresLocation:pageDescription'),
    keywords: t('StoresLocation:keywords')
  },
  ALLERGENS: {
    path: `${PUBLIC_CDN}Alergenos+Espa%C3%B1a/TablaAlergenos+ED+44.pdf`,
    title: t('Footer:allergies')
  },
  NUTRITIONAL_INFO: {
    path: `${PUBLIC_CDN}Alergenos+España/Inf_NutricionalEspaña+Ed+27.pdf`,
    title: t('Footer:nutritionalInformation')
  },
  GLUTEN_FREE: {
    path: `${PUBLIC_CDN}gluten/singluten.pdf`,
    title: t('Footer:glutenFree')
  },
  PROMOS: {
    exact: true,
    path: t('Promotions:path'),
    title: t('Promotions:pageTitle'),
    description: t('Promotions:description')
  },
  LOGIN: {
    exact: true,
    path: '/login',
    title: t('Home:pageTitle'),
    description: t('Home:pageDescription'),
    keywords: t('Home:keywords')
  }
};

export const CLIENT_PAGES_NAME = {
  [CLIENT_PAGES.HOME.path]: 'home',
  [CLIENT_PAGES.CHECKOUT.path]: 'checkout',
  [CLIENT_PAGES.PURCHASE_OK.path]: 'purchase',
  [CLIENT_PAGES.PURCHASE_FAIL.path]: 'purchase',
  [CLIENT_PAGES.STORES.path]: 'stores',
  [CLIENT_PAGES.STORE.path]: 'store',
  [CLIENT_PAGES.ORDER.path]: 'orders',
  [CLIENT_PAGES.PROFILE.path]: 'profile',
  [CLIENT_PAGES.PAPA_POINTS.path]: 'papa-points',
  [CLIENT_PAGES.GIFT_CARD.path]: 'gift-cards',
  [CLIENT_PAGES.ORDER_BUILDER.basePath]: 'order-builder'
};

export const getRouteName = path => CLIENT_PAGES_NAME[`/${path.split('/')[1]}`];
