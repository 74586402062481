/* eslint-disable complexity */
import { t } from 'i18next';

import { NOTIFICATION_DANGER, NOTIFICATION_SUCCESS } from '~redux/Notification/constants';
import { CURRENT_COUNTRY_ID, CARD_PAYMENT_TYPE, COUNTRIES } from '~constants/environment';
import {
  PAYMENT_METHODS_SERVER,
  CARD_PAYMENT_METHODS_ONLINE,
  PAYMENT_METHODS_ONLINE,
  PAYMENT_METHODS
} from '~constants/paymentMethods';
import { arrayHasLength } from '~utils/array';

import { getQueryParams } from './parseUrl';

export const getCreditCardString = number => `* * * * ${number}`;

export const verifyAddCreditCardError = ({ search, showNotification, clearSearch }) => {
  if (search) {
    const { error, message } = getQueryParams(search);
    if (error === 'true') {
      showNotification({
        message: message.includes('cancelled')
          ? t('CheckoutPaymentMethodList:addCardErrorCancelled')
          : t('CheckoutPaymentMethodList:addCardError'),
        type: NOTIFICATION_DANGER
      });
    } else {
      showNotification({
        message: t('CheckoutPaymentMethodList:addCardSuccess'),
        type: NOTIFICATION_SUCCESS
      });
    }
    clearSearch();
  }
};

export const shouldShowCreditCards = (paymentMethods, isRegistered, paymentCardMethods) => {
  if ([COUNTRIES.es, COUNTRIES.pt].includes(CURRENT_COUNTRY_ID) && !arrayHasLength(paymentCardMethods)) {
    return false;
  }
  return (
    CARD_PAYMENT_TYPE &&
    isRegistered &&
    Object.keys(paymentMethods).some(
      payment =>
        payment === CARD_PAYMENT_TYPE ||
        (payment === PAYMENT_METHODS_SERVER.webOnline &&
          CARD_PAYMENT_TYPE === CARD_PAYMENT_METHODS_ONLINE.directClickToPay) ||
        payment === PAYMENT_METHODS_SERVER.mercantil
    )
  );
};

export const mercantilEnabled = currentSubsidiary => currentSubsidiary?.paymentMethods?.mercantil;

export const externalMethodsByCountry = {
  es: {
    [CARD_PAYMENT_METHODS_ONLINE.ingenico]: PAYMENT_METHODS.ingenico,
    [PAYMENT_METHODS.sodexo]: PAYMENT_METHODS.directClickToPay,
    [PAYMENT_METHODS.edenred]: PAYMENT_METHODS.directClickToPay
  },
  cr: {
    ...PAYMENT_METHODS_ONLINE.powertranz,
    [PAYMENT_METHODS.powertranzGuest]: PAYMENT_METHODS_ONLINE.powertranz
  },
  pa: {
    ...PAYMENT_METHODS_ONLINE.powertranz,
    [PAYMENT_METHODS.powertranzGuest]: PAYMENT_METHODS_ONLINE.powertranz
  },
  gt: {
    ...PAYMENT_METHODS_ONLINE.powertranz,
    [PAYMENT_METHODS.powertranzGuest]: PAYMENT_METHODS_ONLINE.powertranz
  }
};

const externalMethodMapping = {
  ...([COUNTRIES.es, COUNTRIES.pt].includes(CURRENT_COUNTRY_ID) && externalMethodsByCountry.es),
  ...([COUNTRIES.cr, COUNTRIES.pa, COUNTRIES.gt].includes(CURRENT_COUNTRY_ID) && externalMethodsByCountry.gt)
};

export const normalizeMethods = (methods, registeredMethods) => {
  const normalized = methods.map(method => {
    const registered = registeredMethods[method];

    if (registered) {
      if (registered.name === PAYMENT_METHODS.powertranzGuest) {
        return PAYMENT_METHODS.powertranz;
      }
      return registered.originalName || registered.name;
    }
    return method;
  });
  const extendedNormalized = normalized.concat(
    Object.keys(externalMethodMapping)
      .filter(method => methods.includes(externalMethodMapping[method]))
      .map(method => registeredMethods[externalMethodMapping[method]]?.name || externalMethodMapping[method])
  );
  return [...new Set(extendedNormalized)];
};

export const isMethodValid = (selectedMethod, normalized) =>
  normalized.includes(selectedMethod) || normalized.includes(externalMethodMapping[selectedMethod]);
