import i18next from 'i18next';

i18next.addResources('es_CL', 'CurrentOrderSection', {
  questionSectionTitle: 'Pedidos Papa John’s',
  checkOrderStatus: 'Consulta el estado de tu pedido',
  responseToStatus:
    '¿Has realizado un pedido y quieres conocer su estado? ¡Haz seguimiento de tu pedido en Papa John’s fácilmente! Podrás consultar el estado del pedido, la hora aproximada de entrega, el detalle de todos los productos incluidos en tu pedido, el precio y los datos del local donde has realizado el pedido.',
  checkOrderHistory: 'Consulta tu Historial de pedidos',
  responseToHistory:
    'También puedes consultar el historial de pedidos anteriores. Accede fácilmente a su historial de pedidos con unos clics, donde podrás revisar todos tus pedidos en Papa John’s. ¿Disfrutaste tu elección más reciente? ¡Repetir tu platillo favorito está a tan solo un clic de distancia! Explora tu historial de pedidos y repite esa deliciosa experiencia.',
  faqTitle: 'Preguntas frecuentes',
  question1: '¿Cómo puedo hacer un pedido?',
  answer1:
    'Para realizar un pedido en línea en Papa John’s, simplemente visita nuestro sitio web o utiliza nuestra aplicación móvil. Selecciona tus productos favoritos del menú, personaliza tu orden según tus preferencias y sigue los pasos para completar el proceso de pago.',
  question2: '¿Puedo modificar la hora de mi pedido?',
  answer2:
    'Sí, puedes ajustar la hora de entrega de tu pedido. Una vez que hayas seleccionado tus productos y estés en la pantalla de pago, encontrarás la opción para modificar la hora y día de entrega. Ten en cuenta que la disponibilidad de horarios puede variar según la ubicación, demanda y día en específico.',
  question3: '¿Puedo cambiar la tienda asignada?',
  answer3:
    'Absolutamente, tienes la opción de cambiar la tienda antes de confirmar tu pedido, únicamente si estás realizando una orden para recoger. En la sección de direcciones, se presentará una lista de tiendas basada en la dirección proporcionada por ti; desde ahí, puedes fácilmente ajustar la tienda a la que prefieres dirigirte para recoger tu pedido.',
  question4: '¿Cuál es el pedido mínimo en Papa John’s?',
  answer4:
    'El Valor del pedido mínimo para delivery es de $12.000, reservándose la Compañía el derecho de modificar su valor en función de la zona de reparto. Para los pedidos en local o retiro en tienda, sin mínimo de compra.',
  question5: 'No reconoce mi dirección, ¿qué hago?',
  answer5:
    'Si experimentas dificultades con la dirección, verifica que la hayas ingresado correctamente. También puedes utilizar la función de búsqueda de direcciones en nuestra plataforma o ponerte en contacto con nuestro servicio de atención al cliente para obtener asistencia adicional.',
  question6: '¿Cómo puedo anular un pedido?',
  answer6:
    'Si necesitas cancelar tu pedido, te recomendamos comunicarte directamente con la tienda. Llamando al teléfono de la tienda, marcando opción 2. Puedes encontrar el teléfono de tu tienda <a class="text-caribbean-green" href="https://www.papajohns.cl/locales">acá</a>. Ten en cuenta que, dependiendo del estado del pedido, es posible que apliquen ciertas restricciones para la cancelación.',
  question7: '¿Puedo revisar mi historial de pedidos?',
  answer7:
    'Sí, puedes revisar tu historial de pedidos iniciando sesión en tu cuenta en nuestro sitio web o aplicación. En la sección de historial de pedidos, encontrarás detalles de todas tus órdenes anteriores, incluidos las cantidades pagadas y la información de los ítems del pedido.',
  question8: '¿Cómo puedo repetir un pedido?',
  answer8:
    'Repetir un pedido es sencillo. Inicia sesión en tu cuenta, dirígete a tu historial de pedidos y elige la orden que deseas volver a solicitar. Además, en la página principal, encontrarás una sección que te permitirá repetir tu último pedido, siempre y cuando los productos estén disponibles. Después, simplemente ajusta los detalles según tus preferencias actuales y concluye el proceso de pago. ¡Disfruta de tu comida favorita con un solo clic!',
  pizzaSectionTitle: 'Mientras esperas descubre más de nuestras pizzas',
  seeMore: 'Ver más'
});

i18next.addResources('pt_PT', 'CurrentOrderSection', {
  questionSectionTitle: 'Encomendas Papa John’s',
  checkOrderStatus: 'Consulte o estado do seu pedido',
  responseToStatus:
    'Realizaste um pedido e queres saber o seu estado? Acompanha facilmente o teu pedido na Papa John’s! Poderás verificar o estado do pedido, a hora aproximada de entrega, o detalhe de todos os produtos incluídos no teu pedido, o preço e os dados do local onde fizeste o pedido',
  checkOrderHistory: 'Consulte o seu histórico de pedidos',
  responseToHistory:
    'Também podes consultar o histórico de pedidos anteriores. Acede facilmente ao teu histórico de pedidos com alguns cliques, onde poderás rever todos os teus pedidos na Papa John’s. Gostaste da tua escolha mais recente? Repetir o teu prato favorito está apenas a um clique de distância! Explora o teu histórico de pedidos e repete essa deliciosa experiência.',
  faqTitle: 'Perguntas frequentes',
  question1: 'Como posso fazer uma encomenda?',
  answer1:
    'Podes fazer uma encomenda na Papa Johns através do nosso site ou da nossa aplicação móvel. Como primeiro passo, terás de selecionar se queres que a encomenda seja entregue ao domicílio ou se preferes levantá-la numa das nossas lojas. De seguida, adiciona os teus produtos preferidos ao carrinho e confirma a tua encomenda completando o processo de pagamento.',
  question2: 'Posso alterar a hora de entrega da minha encomenda?',
  answer2:
    'Sim, podes modificar a hora de entrega da tua encomenda. Depois de teres selecionado os teus produtos e chegado ao ecrã de pagamento, encontrarás a opção para alterar a hora de entrega. Tem em conta que a disponibilidade de horários pode variar consoante a localização e a procura.',
  question3: 'Posso mudar a loja que me foi atribuída?',
  answer3:
    'Claro que sim! Podes alterar a loja onde queres levantar a tua encomenda antes de a confirmar. Em função da morada fornecida, serão apresentadas as lojas mais próximas.',
  question4: 'Qual é a encomenda mínima na Papa Johns?',
  answer4:
    'A encomenda mínima pode variar consoante a localização da loja. Para saberes a quantidade mínima de encomenda na tua área, recomendamos que verifiques esta informação no site ou na aplicação móvel ao realizares a tua encomenda.',
  question5: 'A minha morada não é reconhecida. O que devo fazer?',
  answer5:
    'Se tiveres dificuldades com a morada, verifica se a inseriste corretamente com todos os dados relevantes (rua, número, etc.). Também podes utilizar a função de pesquisa de moradas ou contactar o nosso serviço de apoio ao cliente para obter ajuda.',
  question6: 'Como posso cancelar uma encomenda?',
  answer6:
    'Ups! Se precisares de cancelar uma encomenda, recomendamos que contactes a nossa equipa de apoio ao cliente o mais rapidamente possível ou que ligues para a loja que está a preparar a tua encomenda. Podes encontrar esta informação na mensagem de e-mail que recebeste com a confirmação da encomenda. Tem em conta que, dependendo do estado da encomenda, podem ser aplicadas certas restrições para o cancelamento.',
  question7: 'Posso consultar o meu histórico de encomendas?',
  answer7:
    'Sim, podes consultar o teu histórico de encomendas iniciando sessão na tua conta através do nosso site ou da nossa aplicação. Na secção histórico de encomendas, encontrarás os detalhes de todas as tuas encomendas anteriores.',
  question8: 'Como posso repetir uma encomenda?',
  answer8:
    'Repetir uma encomenda anterior é fácil. Inicia sessão na tua conta, acede ao teu histórico de encomendas e escolhe a encomenda que pretendes voltar a encomendar. Além disso, na página inicial, encontrarás uma secção que te permitirá repetir a tua última encomenda, desde que os produtos estejam disponíveis. Depois, simplesmente terás de alterar os detalhes de acordo com as tuas preferências atuais e confirmar a encomenda com o processo de pagamento.',
  pizzaSectionTitle: 'Enquanto espera, descubra mais das nossas pizzas',
  seeMore: 'Ver mais'
});

i18next.addResources('es_ES', 'CurrentOrderSection', {
  responseToStatus:
    'Aquí puedes hacer el seguimiento de tu pedido Papa Johns, donde puedes consultar en qué estado se encuentra y la hora aproximada de entrega, el detalle de tus pizzas, entrantes, bebidas, etc. También puedes consultar los datos de tu pizzería más cercana donde puedes pasar a recoger tu pedido o desde donde lo estamos preparando cuidadosamente para que llegue a tu domicilio.',
  responseToHistory:
    'Consulta tu historial de pedidos anteriores donde podrás acceder fácilmente para revisar todos tus pedidos en Papa Johns. Desde el historial de pedidos puedes volver a pedir lo que más te gusta con tan solo un clic. Si te gustó la última pizza que probaste añádela al carrito desde aquí y disfrútala.',
  faqTitle: 'Preguntas frecuentes',
  answer1:
    'Puedes hacer un pedido en Papa Johns a través de nuestra página web o aplicación móvil. Como primer paso tendrás que seleccionar si quieres tu pedido a domicilio o prefieres pasarte por una de nuestras pizzerías. Luego agrega al carrito tus productos favoritos y confirma tu pedido completando el proceso de pago.',
  answer2:
    'Sí, puedes ajustar la hora de entrega de tu pedido. Una vez que hayas seleccionado tus productos y llegues a la pantalla de pago, encontrarás la opción para modificar la hora de entrega. Ten en cuenta que la disponibilidad de horarios puede variar según la ubicación y la demanda.',
  question3: '¿Puedo cambiar la pizzería asignada?',
  answer3:
    '¡Por supuesto! Puedes cambiar la pizzería desde la cual quieres recoger el pedido antes de confirmarlo. En función de la dirección proporcionada, se te indicarán las pizzerías más cercanas.',
  question4: '¿Cuál es el pedido mínimo en Papa Johns?',
  answer4:
    'El pedido mínimo puede variar según la ubicación de la pizzería. Para conocer la cantidad mínima del pedido en tu zona, te recomendamos verificar esta información en la página web o en la aplicación móvil al hacer tu pedido.',
  answer5:
    'Si experimentas dificultades con la dirección, verifica que la hayas ingresado correctamente con todos los datos correspondientes (calle, número, etc). También puedes utilizar la función de búsqueda de direcciones o ponerte en contacto con nuestro servicio de atención al cliente para obtener ayuda.',
  answer6:
    'Si necesitas cancelar tu pedido, te recomendamos que te pongas en contacto con nuestro equipo de atención al cliente lo antes posible o que llames a la pizzería que está elaborando tu pedido. Esta información la puedes encontrar en el correo electrónico que habrás recibido con la confirmación del pedido. Ten en cuenta que, dependiendo del estado del pedido, es posible que apliquen ciertas restricciones para la cancelación.',
  answer7:
    'Sí, puedes revisar tu historial de pedidos iniciando sesión en tu cuenta en nuestra página web o aplicación. En la sección de historial de pedidos, encontrarás detalles de todos tus pedidos anteriores.',
  answer8:
    'Repetir un pedido es sencillo. Inicia sesión en tu cuenta, dirígete a tu historial de pedidos y elige el pedido que deseas volver a pedir. Además, en la página principal, encontrarás una sección que te permitirá repetir tu último pedido, siempre y cuando los productos estén disponibles. Después, simplemente tendrás que ajustar los detalles según tus preferencias actuales y confirmar el pedido con el proceso de pago.'
});

i18next.addResources('es_GT', 'CurrentOrderSection', {
  answer1:
    'Para disfrutar de la facilidad de ordenar en línea en Papa John’s, accede a nuestro sitio web o descarga nuestra aplicación móvil. Una vez dentro, navega a través de nuestro menú. Selecciona tus productos favoritos y personaliza tu orden según tus gustos, sigue los pasos para completar el proceso de pago.',
  answer2:
    'Papa John’s te ofrece la flexibilidad necesaria. Sí, puedes ajustar la hora de entrega de tu pedido. Durante el proceso de pago, una vez que hayas seleccionado tus productos deseados y estés en la pantalla de pago, encontrarás la opción de modificar la hora de entrega. Es importante tener en cuenta que la disponibilidad de horarios puede variar según la ubicación y la demanda.',
  answer3:
    'Papa John’s te brinda la facilidad de cambiar la tienda asignada antes de confirmar tu pedido, especialmente si estás optando por hacer una compra para retiro. Dentro de la sección de direcciones, se desplegará la lista de tiendas basadas en la dirección proporcionada. Desde ahí puedes fácilmente seleccionar la tienda de tu preferencia para recoger tu pedido.',
  answer4:
    'El pedido mínimo en Papa John’s puede variar según la ubicación de la tienda. Para obtener información precisa sobre el monto mínimo de pedido en tu área, te recomendamos verificar esta información a través de nuestro sitio web o la aplicación móvil al momento de realizar tu orden.',
  answer5:
    'En caso de experimentar dificultades con tu dirección, te recomendamos verificar que la hayas ingresado correctamente. Además, puedes aprovechar la función de búsqueda “usar mi ubicación actual” integrada en nuestra plataforma o contactar a nuestro equipo de servicio al cliente para recibir asistencia adicional.',
  question6: '¿Cómo puedo cancelar un pedido?',
  answer6:
    'Si necesitas cancelar tu pedido, te sugerimos que te pongas en contacto con servicio al cliente de inmediato. Es importante tener en cuenta que, dependiendo del estado del pedido, podrían aplicarse ciertas restricciones para la cancelación, por lo que recomendamos actuar con prontitud.',
  answer7:
    '¡Por supuesto! En Papa John’s, ofrecemos la comodidad de revisar tu historial de pedidos simplemente iniciando sesión en tu cuenta a través de nuestro sitio web o aplicación móvil. Dentro de la sección historial de pedidos, encontrarás un desglose detallado de todas tus órdenes anteriores, incluidos los montos pagados y la información completa de los productos solicitados.',
  answer8:
    'Repetir tu pedido favorito en Papa John’s es un proceso rápido y sencillo. Simplemente inicia sesión en tu cuenta, accede a tu historial de pedidos y selecciona la orden que deseas repetir. Además, en la página principal encontrarás una sección que te permite volver a ordenar tu último pedido con tan solo un clic, siempre y cuando los productos estén disponibles. Luego, ajusta los detalles según tus preferencias actuales y completa el proceso de pago con total facilidad.'
});

i18next.addResources('es_CR', 'CurrentOrderSection', {
  answer1:
    'Para disfrutar de la facilidad de ordenar en línea en Papa John’s, accede a nuestro sitio web o descarga nuestra aplicación móvil. Una vez dentro, navega a través de nuestro menú. Selecciona tus productos favoritos y personaliza tu orden según tus gustos, sigue los pasos para completar el proceso de pago.',
  answer2:
    'Papa John’s te ofrece la flexibilidad necesaria. Sí, puedes ajustar la hora de entrega de tu pedido. Durante el proceso de pago, una vez que hayas seleccionado tus productos deseados y estés en la pantalla de pago, encontrarás la opción de modificar la hora de entrega. Es importante tener en cuenta que la disponibilidad de horarios puede variar según la ubicación y la demanda.',
  answer3:
    'Papa John’s te brinda la facilidad de cambiar la tienda asignada antes de confirmar tu pedido, especialmente si estás optando por hacer una compra para retiro. Dentro de la sección de direcciones, se desplegará la lista de tiendas basadas en la dirección proporcionada. Desde ahí puedes fácilmente seleccionar la tienda de tu preferencia para recoger tu pedido.',
  answer4:
    'El pedido mínimo en Papa John’s puede variar según la ubicación de la tienda. Para obtener información precisa sobre el monto mínimo de pedido en tu área, te recomendamos verificar esta información a través de nuestro sitio web o la aplicación móvil al momento de realizar tu orden.',
  answer5:
    'En caso de experimentar dificultades con tu dirección, te recomendamos verificar que la hayas ingresado correctamente. Además, puedes aprovechar la función de búsqueda “usar mi ubicación actual” integrada en nuestra plataforma o contactar a nuestro equipo de servicio al cliente para recibir asistencia adicional.',
  question6: '¿Cómo puedo cancelar un pedido?',
  answer6:
    'Si necesitas cancelar tu pedido, te sugerimos que te pongas en contacto con servicio al cliente de inmediato. Es importante tener en cuenta que, dependiendo del estado del pedido, podrían aplicarse ciertas restricciones para la cancelación, por lo que recomendamos actuar con prontitud.',
  answer7:
    '¡Por supuesto! En Papa John’s, ofrecemos la comodidad de revisar tu historial de pedidos simplemente iniciando sesión en tu cuenta a través de nuestro sitio web o aplicación móvil. Dentro de la sección historial de pedidos, encontrarás un desglose detallado de todas tus órdenes anteriores, incluidos los montos pagados y la información completa de los productos solicitados.',
  answer8:
    'Repetir tu pedido favorito en Papa John’s es un proceso rápido y sencillo. Simplemente inicia sesión en tu cuenta, accede a tu historial de pedidos y selecciona la orden que deseas repetir. Además, en la página principal encontrarás una sección que te permite volver a ordenar tu último pedido con tan solo un clic, siempre y cuando los productos estén disponibles. Luego, ajusta los detalles según tus preferencias actuales y completa el proceso de pago con total facilidad.'
});

i18next.addResources('es_PA', 'CurrentOrderSection', {
  answer1:
    'Para disfrutar de la conveniencia de ordenar en línea en Papa John’s, ingresa a nuestro sitio web o descarga nuestra aplicación móvil. Una vez dentro, navega a través de nuestro menú. Selecciona tus productos favoritos y personaliza tu orden según tus gustos, sigue los pasos para completar el proceso de pago.',
  answer2:
    'Papa John’s te ofrece la flexibilidad necesaria. Sí, puedes ajustar la hora de entrega de tu pedido. Durante el proceso de pago, una vez que hayas seleccionado tus productos deseados y estés en la pantalla de pago, encontrarás la opción de modificar la hora de entrega. Es importante tener en cuenta que la disponibilidad de horarios puede variar según la ubicación y la demanda.',
  answer3:
    'Papa John’s te brinda la conveniencia de cambiar la tienda asignada antes de confirmar tu pedido, especialmente si estás optando por hacer una compra para retiro. Dentro de la sección de direcciones, se desplegará la lista de tiendas basadas en la dirección proporcionada. Desde ahí puedes fácilmente seleccionar la tienda de tu preferencia para recoger tu pedido.',
  answer4:
    'El pedido mínimo en Papa John’s puede variar según la ubicación de la tienda. Para obtener información precisa sobre el monto mínimo de pedido en tu área, te recomendamos verificar esta información a través de nuestro sitio web o la aplicación móvil al momento de realizar tu orden.',
  answer5:
    'En caso de experimentar dificultades con tu dirección, te recomendamos verificar que hayas ingresado los datos correctamente. Además, puedes aprovechar la función de búsqueda “usar mi ubicación actual” integrada en nuestra plataforma o contactar a nuestro equipo de servicio al cliente para recibir asistencia adicional.',
  question6: '¿Cómo puedo cancelar un pedido?',
  answer6:
    'Si necesitas cancelar tu pedido, te sugerimos ponerte en contacto de inmediato con nuestro equipo de servicio al cliente. Es importante tener en cuenta que, dependiendo del estado de tu pedido, podrían aplicarse ciertas restricciones para la cancelación, por lo que recomendamos actuar con prontitud.',
  answer7:
    '¡Por supuesto! En Papa John’s, ofrecemos la comodidad de revisar tu historial de pedidos simplemente iniciando sesión en tu cuenta a través de nuestro sitio web o aplicación móvil. Dentro de la sección historial de pedidos, encontrarás un desglose detallado de todas tus órdenes anteriores, incluidos los montos pagados y la información completa de los productos solicitados.',
  answer8:
    'Repetir tu pedido favorito en Papa John’s es un proceso rápido y sencillo. Simplemente inicia sesión en tu cuenta, accede a tu historial de pedidos y selecciona la orden que deseas repetir. Además, en la página principal encontrarás una sección que te permite volver a ordenar tu último pedido con tan solo un clic, siempre y cuando los productos estén disponibles. Luego, ajusta los detalles según tus preferencias actuales y completa el proceso de pago con total facilidad.'
});
