import { t } from 'i18next';
import * as Yup from 'yup';

export const getInstructions = ({ amount, phoneSinpeMovil }) => [
  {
    id: 'step1',
    number: t('SinpeMovilModal:instruction1.number'),
    text: t('SinpeMovilModal:instruction1.text', { amount, phoneSinpeMovil })
  },
  {
    id: 'step2',
    number: t('SinpeMovilModal:instruction2.number'),
    text: t('SinpeMovilModal:instruction2.text')
  },
  {
    id: 'step3',
    number: t('SinpeMovilModal:instruction3.number'),
    text: t('SinpeMovilModal:instruction3.text')
  }
];

const cedulaSchema = Yup.string()
  .matches(/^0\d-\d{4}-\d{4}$/, t('SinpeMovilModal:invalidFormatCI'))
  .required(t('SinpeMovilModal:obligatoryCI'));

const idValidations = {
  [t('SinpeMovilModal:identificationTypeOptions.Cédula Física')]: cedulaSchema
};

export const validateIdentification = (type, value) => {
  const schema = idValidations[type];

  if (!schema) {
    return null;
  }

  try {
    schema.validateSync(value);
    return null;
  } catch (error) {
    return error.message;
  }
};
