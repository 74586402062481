import i18next from 'i18next';

i18next.addResources('es_CL', 'NavMain', {
  menu: 'Menú',
  points: 'Papa Puntos',
  order: 'Pedidos',
  stores: 'Locales',
  login: 'Ingresar',
  logout: 'Salir de la cuenta',
  onlineChat: 'Chat en línea',
  myAccount: 'Mi cuenta',
  openSideMenu: 'Abrir menú lateral',
  giftCard: 'Gift Card'
});

i18next.addResources('es_ES', 'NavMain', {
  menu: 'Menú',
  points: 'Papa Puntos',
  order: 'Pedidos',
  stores: 'Locales',
  login: 'Entrar',
  logout: 'Salir de la cuenta',
  myAccount: 'Mi cuenta',
  giftCard: 'Tarjeta Regalo'
});

i18next.addResources('pt_PT', 'NavMain', {
  menu: 'Menú',
  points: 'Papa Pontos',
  order: 'Encomendas',
  stores: 'Lojas',
  login: 'A minha conta',
  logout: 'Sair',
  onlineChat: 'Conversa connosco',
  myAccount: 'Minha conta',
  openSideMenu: 'Abrir menu lateral'
});
