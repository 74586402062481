import i18next from 'i18next';

import { CRUSTS, SIZES } from './constants';

i18next.addResources('es_CL', 'Home', {
  pageTitle: 'Papa Johns Chile 🥇 Pizzas con ingredientes frescos y naturales',
  pageDescription:
    'Pide ahora y disfruta de la mejor pizza 🍕 a domicilio o para recoger ⭐ ¡Revisa las promociones y ofertas exclusivas que Papa Johns tiene para ti!',
  keywords: 'Papa Johns',
  [CRUSTS.ORIGINAL]: 'Original',
  [CRUSTS.TRADITIONAL]: 'Tradicional',
  [CRUSTS.THIN]: 'Delgada',
  [CRUSTS.STUFFED]: 'Borde de queso mozzarella',
  [CRUSTS.PARMESAN]: 'Masa Maestra',
  [CRUSTS.PHILADELPHIA]: 'Borde de queso cheddar',
  [CRUSTS.CAULIFLOWER]: 'Masa de Coliflor',
  [CRUSTS.PARMESAN_BASE]: 'Base Parmesano',
  [CRUSTS.PEPPERONI]: 'Borde Pepperonísimo',
  [SIZES.INDIVIDUAL]: 'Individual',
  [SIZES.REGULAR]: 'Regular',
  [SIZES.MEDIUM]: 'Mediana',
  [SIZES.FAMILY]: 'Familiar',
  [SIZES.EXTRA_LARGE]: 'Extra grande',
  [SIZES.PERSONAL]: 'Personal',
  title: "Pizzería Papa John's Chile",
  description:
    "Descubre nuestras deliciosas pizzas Papa Johns realizadas con los mejores ingredientes, masa fresca, salsa de tomate, queso 100% mozzarella y vegetales recién cortados. Pídelas para delivery, retiro en tienda o disfrútalas en tu pizzería Papa John's más cercana. ¡Mejores Ingredientes, Mejor Pizza!"
});

i18next.addResources('es_CR', 'Home', {
  pageTitle: 'Papa Johns | La mejor pizza de Costa Rica, mejores ingredientes',
  pageDescription:
    'Disfrutá la mejor pizza a domicilio o recoger en tu restaurante más cercano. Descubrí las promociones y ofertas exclusivas que Papa Johns tiene para vos',
  [CRUSTS.ORIGINAL]: 'Original Delgada',
  [CRUSTS.TRADITIONAL]: 'Tradicional Gruesa',
  [CRUSTS.THIN]: 'Delgada / Thin Crust',
  [CRUSTS.STUFFED]: 'Borde relleno de queso',
  [CRUSTS.PARMESAN]: 'Borde Crujiente',
  [SIZES.REGULAR]: 'Pizza 10"',
  [SIZES.FAMILY]: 'Grande',
  [SIZES.EXTRA_LARGE]: 'XLarge',
  keywords: 'Pizzeria',
  title: "Pizzería Papa John's Costa Rica",
  description:
    '¡Descubrí nuestras deliciosas pizzas de Papa Johns hechas con los mejores ingredientes! Usamos masa fresca, salsa de tomate, queso 100% mozzarella y vegetales recién cortados. Ordénalas para entrega a domicilio, recoger o disfrútalas en tu restaurante Papa Johns más cercano. ¡Mejores ingredientes, mejor pizza!'
});

i18next.addResources('es_ES', 'Home', {
  pageTitle: 'Papa Johns | La mejor pizza con los mejores ingredientes',
  pageDescription:
    'Disfruta de la mejor pizza a domicilio o a recoger en tu pizzería Papa Johns más cercana. Masa fresca e ingredientes naturales. Descubre todas las ofertas',
  keywords: 'Papa Johns',
  [CRUSTS.TRADITIONAL]: 'Original',
  [CRUSTS.THIN]: 'Fina',
  [CRUSTS.PARMESAN]: 'Borde gratinado parmesano',
  [CRUSTS.PHILADELPHIA]: 'Borde de queso Philadelphia',
  [CRUSTS.BRIE]: 'Borde de queso con brie',
  [CRUSTS.PEPPERONI]: 'Borde Pepperonísimo',
  [SIZES.FAMILY]: 'Familiar',
  [SIZES.EXTRA_LARGE]: 'XXL',
  knowMore: 'Saber más',
  exchange: 'Canjear',
  joinClub: 'Unirme al club',
  buyAndGetPoints: 'Acumula Papa Puntos con tus compras',
  startUsingPoints: 'Empieza a acumular Papa Puntos',
  joinAndGetPoints:
    'Únete y acumula puntos con tus compras para canjearlos por pizzas, acompañamientos y más.',
  title: 'Pizzería Papa Johns España',
  description:
    'Descubre nuestras deliciosas pizzas Papa Johns realizadas con los mejores ingredientes, masa fresca, salsa de tomate, queso 100% mozzarella y vegetales recién cortados. Pídelas con entrega a domicilio, para recoger o disfrutarlas en tu pizzería Papa Johns más cercana. ¡Better Ingredients, Better Pizza!'
});

i18next.addResources('pt_PT', 'Home', {
  pageTitle: 'Papa Johns | A melhor pizza com os melhores ingredientes',
  pageDescription:
    'Desfruta da melhor pizza ao domicílio ou para levantar na tua pizzaria Papa Johns mais próxima. Pizzas feitas com massa fresca e ingredientes naturais',
  [CRUSTS.TRADITIONAL]: 'Original',
  [CRUSTS.THIN]: 'Fina',
  [CRUSTS.STUFFED]: 'Rebordo de queijo mozzarella',
  [SIZES.INDIVIDUAL]: 'Tamanho individual',
  [CRUSTS.PARMESAN]: 'Rebordo Gratinado de Parmesão',
  [CRUSTS.PHILADELPHIA]: 'Rebordo de philadelphia',
  [CRUSTS.BRIE]: 'Rebordo de quijo com brie',
  [CRUSTS.CAULIFLOWER]: 'Massa de couve-flor',
  [CRUSTS.PARMESAN_BASE]: 'Base de parmesão',
  [CRUSTS.PEPPERONI]: 'Rebordo Pepperonísimo',
  [SIZES.MEDIUM]: 'Média',
  [SIZES.FAMILY]: 'Grande',
  [SIZES.EXTRA_LARGE]: 'XXL',
  knowMore: 'Saber mais',
  exchange: 'Intercâmbio',
  joinClub: 'Juntese ao clube',
  buyAndGetPoints: 'Acumule Papa Pontos com suas compras',
  startUsingPoints: 'Comece a acumular Papa Pontos',
  joinAndGetPoints:
    'Participe e acumule pontos com suas compras para trocar por pizzas, acompanhamentos e muito mais.',
  title: 'Pizzería Papa Johns Portugal',
  description:
    'Descobre as nossas deliciosas pizzas Papa Johns feitas com os melhores ingredientes, massa fresca, molho de tomate, queijo 100% mozzarella e vegetais recém-cortados. Encomenda-as com entrega ao domicílio, em take-away ou para saborear na tua pizzaria Papa Johns mais próxima. Better Ingredients, Better Pizza!'
});

i18next.addResources('es_PA', 'Home', {
  pageTitle: 'Papa Johns | La mejor pizza de Panamá, mejores ingredientes',
  pageDescription:
    'Disfruta la mejor pizza a domicilio o recoger en tu restaurante más cercano. Descubre las promociones y ofertas exclusivas que Papa Johns tiene para ti',
  [SIZES.EXTRA_LARGE]: 'XLarge',
  [CRUSTS.ORIGINAL]: 'Original Delgada',
  [CRUSTS.TRADITIONAL]: 'Tradicional Gruesa',
  [CRUSTS.THIN]: 'Delgada / Thin Crust',
  [CRUSTS.STUFFED]: 'Borde relleno de queso',
  [CRUSTS.PARMESAN]: 'Borde Crujiente',
  title: "Pizzería Papa John's Panamá",
  description:
    'Descubre nuestras deliciosas pizzas de Papa Johns recién horneadas, hechas con los mejores ingredientes; masa fresca, salsa de tomate, queso 100% mozzarella y vegetales recién cortados. Pide para entrega a domicilio, pasa a retirar o disfrútalas en tu pizzería Papa Johns más cercana. ¡Mejores Ingredientes, Mejor Pizza!'
});

i18next.addResources('es_GT', 'Home', {
  pageTitle: 'Papa Johns | La mejor pizza de Guatemala, mejores ingredientes',
  pageDescription:
    'Disfrutá la mejor pizza al chilazo a domicilio o recoger en tu restaurante más cercano. Descubrí las promociones exclusivas que Papa Johns tiene para vos',
  [SIZES.FAMILY]: 'Grande',
  [CRUSTS.ORIGINAL]: 'Original Delgada',
  [CRUSTS.TRADITIONAL]: 'Tradicional Gruesa',
  [CRUSTS.THIN]: 'Delgada / Thin Crust',
  [CRUSTS.STUFFED]: 'Borde relleno de queso',
  [CRUSTS.PARMESAN]: 'Borde Crujiente',
  [SIZES.REGULAR]: 'Pizza 10"',
  title: "Pizzería Papa John's Guatemala",
  description:
    'Descubrí nuestras deliciosas pizzas Papa Johns recién horneadas, hechas con los mejores ingredientes; masa fresca, salsa de tomate, queso 100% mozzarella y vegetales recién cortados. Pídelas para entrega a domicilio, pasa a recoger o disfrútalas en tu pizzería Papa Johns más cercana. ¡Mejores Ingredientes, Mejor Pizza!'
});
