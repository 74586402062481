import i18next from 'i18next';

import { LANGUAGE_KEYS } from '~constants/languages';

import { abandonedCartUTM } from './constants';

i18next.addResources(LANGUAGE_KEYS.CHILE, 'abandonedCart', {
  title: '¡Tu pedido se enfría!',
  body: 'Estás a un clic de disfrutar tu pedido',
  icon: 'https://papajohns.cl/icons/apple-touch-icon.png',
  url: `https://www.papajohns.cl/${abandonedCartUTM}`
});

i18next.addResources(LANGUAGE_KEYS.SPAIN, 'abandonedCart', {
  title: '¡El horno está encendido!',
  body: 'Tu pizza con los mejores ingredientes te espera.',
  icon: 'https://papajohns.es/icons/apple-touch-icon.png',
  url: `https://www.papajohns.es/${abandonedCartUTM}`
});

i18next.addResources(LANGUAGE_KEYS.COSTA_RICA, 'abandonedCart', {
  title: '¡Tu pedido se enfría!',
  body: 'Estás a un clic de disfrutar tu pedido',
  icon: 'https://papajohns.cr/icons/apple-touch-icon.png',
  url: `https://www.papajohns.cr/${abandonedCartUTM}`
});

i18next.addResources(LANGUAGE_KEYS.PANAMA, 'abandonedCart', {
  title: '¡Tu pedido se enfría!',
  body: 'Estás a un clic de disfrutar tu pedido',
  icon: 'https://papajohns.pa/icons/apple-touch-icon.png',
  url: `https://www.papajohns.pa/${abandonedCartUTM}`
});

i18next.addResources(LANGUAGE_KEYS.GUATEMALA, 'abandonedCart', {
  title: '¡Tu pedido se enfría!',
  body: 'Estás a un clic de disfrutar tu pedido',
  icon: 'https://papajohns.gt/icons/apple-touch-icon.png',
  url: `https://www.papajohns.gt/${abandonedCartUTM}`
});

i18next.addResources(LANGUAGE_KEYS.PORTUGAL, 'abandonedCart', {
  title: 'A tua encomenda está a arrefecer!',
  body: 'Estás a um clique de aproveitar da tua encomenda',
  icon: 'https://papajohns.pt/icons/apple-touch-icon.png',
  url: `https://www.papajohns.pt/${abandonedCartUTM}`
});
