/* eslint-disable max-lines */
import { t } from 'i18next';
import { create } from 'apisauce';
import { AuthenticationDetails } from 'amazon-cognito-identity-js';

import PollingService from '../PollingService';

import { PAYMENT_CARD_CREATION_URL, CARD_PAYMENT_METHODS_ONLINE } from '~constants/paymentMethods';
import { errorNormalizer } from '~config/apiTransforms';
import { cognitoUserPool, getCognitoUser, getSessionTokens } from '~config/cognito';
import { serializer as baseSerializer } from '~services/baseSerializers';
import api, { setTokenHeader, socialLoginApi } from '~config/api';
import cognitoErrors from '~constants/errors';
import { getFormattedField } from '~utils/inputValidations';
import { CARD_PAYMENT_TYPE, RENDER_CALL_CENTER } from '~constants/environment';
import { sendGTMEvent, EVENT, eventCategory, eventAction } from '~utils/analytics';
import { isUserComplete } from '~mappers/user';

import { companySerializer } from './serializers';
import { SUCCESS_MESSAGE, PHONE_TAKEN } from './constants';
import { getPhoneTakenError, getNewsletterBody, getNewUserBody } from './utils';

const userToObject = userData => {
  const user = {};

  userData.forEach(attr => {
    user[attr.Name] = attr.Value;
  });

  return user;
};

const resolvePromise = (resolve, err, result, shouldTrackError) => {
  if (err) {
    const problem = getPhoneTakenError(err.message)
      ? cognitoErrors[PHONE_TAKEN]
      : cognitoErrors[err.code] || cognitoErrors[err.description] || err.message;

    if (shouldTrackError) {
      sendGTMEvent(EVENT.eventPJ, {
        eventActionCategory: eventCategory.interaction,
        action: eventAction.invalidRegister,
        label: problem
      });
    }

    return resolve({
      ok: false,
      problem: problem || t('APIErrors:eundefined')
    });
  }

  return resolve({ ok: true, data: result });
};

const pollingServices = {
  addPaymentCard: ({ card, isMercantil, callbackPath }) => {
    let body = {};
    if (CARD_PAYMENT_TYPE === CARD_PAYMENT_METHODS_ONLINE.powertranz && !isMercantil) {
      body = baseSerializer.serialize({
        card: {
          cardNumber: card.cardNumber,
          expiryDate: card.expiryDate
        }
      });
    } else if (CARD_PAYMENT_TYPE === CARD_PAYMENT_METHODS_ONLINE.oneclick) {
      body = baseSerializer.serialize({ callbackPath: callbackPath.replace('/', '') });
    } else if (isMercantil) {
      body = baseSerializer.serialize({
        card: {
          cardNumber: card.TokenDetails.CardNumber,
          cardToken: card.TokenDetails.AccountToken
        }
      });
    }
    return api.post(
      `v1/users/cards${isMercantil ? '/mercantil' : PAYMENT_CARD_CREATION_URL[CARD_PAYMENT_TYPE]}`,
      body
    );
  },
  deleteOneclickPaymentCard: id => api.delete(`/v1/users/cards/oneclick/${id}`),
  usersPolling: ({ token }) => {
    const temporalApi = create({
      baseURL: process.env.REACT_APP_CORE_HOST
    });

    temporalApi.addResponseTransform(errorNormalizer);

    return temporalApi.get('/v1/users_job', {}, { headers: { 'X-JOB-AUTHORIZATION': token } });
  }
};

const services = {
  login: authData => {
    const formattedAuthData = {
      ...authData,
      Username: getFormattedField.email(authData.Username)
    };
    const authenticationDetails = new AuthenticationDetails(formattedAuthData);
    const cognitoUser = getCognitoUser(formattedAuthData.Username);

    return new Promise(resolve => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess(result) {
          const tokens = getSessionTokens(result);

          setTokenHeader(tokens.idToken);
          cognitoUser.getUserAttributes((err, userResult) => {
            if (err) {
              resolve({ ok: false, problem: err });
            } else {
              const user = { ...userToObject(userResult), ...tokens };

              resolve({ ok: true, data: user });
            }
          });
        },
        onFailure(err) {
          resolve({ ok: false, problem: err });
        }
      });
    });
  },
  logout: () => {
    cognitoUserPool.getCurrentUser()?.signOut();
  },
  subscribeNewsletter: user => api.post('v1/users/subscribe_to_newsletter', getNewsletterBody(user)),
  signUp: authData =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async resolve => {
      // eslint-disable-line
      const response = await api.post('/v1/users/sign_up', getNewUserBody(authData));

      return response.ok
        ? resolvePromise(resolve, null, response.data)
        : resolvePromise(resolve, response.data, null, true);
    }),
  getCurrentUser: async () => {
    const response = await api.get('v1/users/me');
    if (response.ok) {
      return { ...response, data: { ...response.data, isComplete: isUserComplete(response.data) } };
    }
    return response;
  },
  createAddress: ({ apartmentDetails = null, dispatchNote = null, ...addressValues }) =>
    api.post(
      'v1/addresses',
      baseSerializer.serialize({
        address: {
          ...addressValues,
          googlePlaceId: addressValues.placeId,
          apartmentDetails,
          dispatchNote
        }
      })
    ),
  updateAddress: ({ apartmentDetails = null, dispatchNote = null, ...addressValues }) =>
    api.patch(
      `v1/addresses/${addressValues.id}`,
      baseSerializer.serialize({
        ...addressValues,
        address: { ...addressValues.address, googlePlaceId: addressValues.address.placeId },
        apartmentDetails,
        dispatchNote
      })
    ),
  deleteAddress: id => api.delete(`v1/addresses/${id}`),
  sendCode: ({ code, username }) => {
    const formattedUsername = getFormattedField.email(username);
    const cognitoUser = getCognitoUser(formattedUsername);

    return new Promise(resolve => {
      cognitoUser.confirmRegistration(code, true, (err, result) => {
        resolvePromise(resolve, err, result);
      });
    });
  },
  resendSMS: username => {
    const formattedUsername = getFormattedField.email(username);
    const cognitoUser = getCognitoUser(formattedUsername);

    return new Promise(resolve => {
      cognitoUser.resendConfirmationCode((err, result) => {
        resolvePromise(resolve, err, result);
      });
    });
  },
  recoverPassword: email => {
    const formattedEmail = getFormattedField.email(email);
    const cognitoUser = getCognitoUser(formattedEmail);

    return new Promise(resolve => {
      cognitoUser.forgotPassword({
        onSuccess(data) {
          resolvePromise(resolve, null, data);
        },
        onFailure(err) {
          resolvePromise(resolve, err);
        }
      });
    });
  },
  socialLogin: body =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async resolve => {
      // eslint-disable-line
      const response = await socialLoginApi.post('/oauth2/token', body);

      if (response.ok) {
        setTokenHeader(response.data.id_token);
        const userData = await services.getCurrentUser();

        if (userData.ok) {
          return resolvePromise(resolve, null, { ...userData.data, tokens: response.data });
        }
        setTokenHeader(null);

        return resolvePromise(resolve, userData.data);
      }

      return resolvePromise(resolve, response.data);
    }),
  setNewPassword: ({ email, password, code }) => {
    const formattedEmail = getFormattedField.email(email);
    const cognitoUser = getCognitoUser(formattedEmail);

    return new Promise(resolve => {
      cognitoUser.confirmPassword(code, password, {
        onSuccess() {
          resolvePromise(resolve, null, SUCCESS_MESSAGE);
        },
        onFailure(err) {
          resolvePromise(resolve, err);
        }
      });
    });
  },
  createGuestUser: userData => {
    const { user } = userData;
    const formattedUserData = {
      ...userData,
      user: {
        ...user,
        email: getFormattedField.email(user.email)
      }
    };

    const url = RENDER_CALL_CENTER ? '/v1/call_center/users' : '/v1/users';
    return api.post(url, baseSerializer.serialize(formattedUserData));
  },
  updateUser: userData => api.patch('v1/users/update_me', userData),
  updatePassword: ({ password, newPassword }) =>
    new Promise(resolve => {
      const cognitoUser = cognitoUserPool.getCurrentUser();

      if (cognitoUser) {
        cognitoUser.getSession(error => {
          if (error) {
            resolvePromise(resolve, error);
          } else {
            cognitoUser.changePassword(password, newPassword, err => {
              if (err) {
                resolvePromise(resolve, err);
              }
              resolvePromise(resolve, null, true);
            });
          }
        });
      }
    }),
  // Companies
  saveCompany: ({ company, externalId }) => {
    const companyData = {
      company: companySerializer.serialize(company)
    };
    let url = company.id
      ? `v1/${RENDER_CALL_CENTER ? 'call_center/' : 'users/'}companies/${company.id}`
      : `v1/${RENDER_CALL_CENTER ? 'call_center/' : 'users/'}companies`;
    if (RENDER_CALL_CENTER) {
      url += `#${externalId}`;
    }
    const response = company.id ? api.patch(url, companyData) : api.post(url, companyData);
    return response;
  },
  getCompanies: externalId => {
    const url = RENDER_CALL_CENTER ? `v1/call_center/companies#${externalId}` : 'v1/users/companies';
    const response = api.get(url);
    return response;
  },
  deleteCompany: ({ id, externalId }) => {
    const url = RENDER_CALL_CENTER
      ? `v1/call_center/companies/${id}#${externalId}`
      : `v1/users/companies/${id}`;
    const response = api.delete(url);
    return response;
  },
  // Companies
  addPaymentCard: ({ card, isMercantil, callbackPath }) =>
    PollingService.poll({
      request: pollingServices.addPaymentCard({ card, isMercantil, callbackPath }),
      polleableService: pollingServices.usersPolling
    }),
  getPaymentCards: (isMercantil = false) =>
    api.get(`/v1/users/cards${isMercantil ? '?provider=mercantil' : ''}`),
  deletePowertranzPaymentCard: id => api.delete(`/v1/users/cards/powertranz/${id}`),
  deleteOneclickPaymentCard: id =>
    PollingService.poll({
      request: pollingServices.deleteOneclickPaymentCard(id),
      polleableService: pollingServices.usersPolling
    }),
  deleteIngenicoPaymentCard: id => api.delete(`/v1/users/cards/ingenico/${id}`),
  sendCodePasswordless: email => {
    const authenticationDetails = new AuthenticationDetails({
      Username: email
    });
    const instanceCognito = getCognitoUser(email);
    return new Promise(resolve => {
      instanceCognito.initiateAuth(authenticationDetails, {
        onFailure(err) {
          resolve({ ok: false, problem: err });
        },
        customChallenge() {
          resolve({ ok: true, instanceCognito });
        }
      });
    });
  },
  codeValidationPasswordless: (code, instanceCognito) =>
    new Promise(resolve => {
      instanceCognito.sendCustomChallengeAnswer(code, {
        onSuccess(session) {
          const tokens = getSessionTokens(session);
          instanceCognito.getUserAttributes((err, userResult) => {
            if (err) {
              resolve({ ok: false, problem: err });
            } else {
              const user = { ...userToObject(userResult), ...tokens };
              resolve({ ok: true, data: user });
            }
          });
        },
        onFailure(err) {
          resolve({ ok: false, problem: err });
        },
        customChallenge() {
          resolve({ ok: false, problem: { code: 'CustomChallengeError' } });
        }
      });
    }),
  signInPasswordless: email => api.post('v1/users/sign_in', { email }),
  selligentAbandonedCart: userAbandonedCart => api.post('v1/users/user_abandoned_cart', userAbandonedCart)
};

export default services;
