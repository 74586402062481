/* eslint-disable camelcase */
/* eslint-disable max-lines */
import {
  createTypes,
  completeTypes,
  withPostSuccess,
  withPostFailure,
  withSuccess,
  withPreFetch
} from 'redux-recompose';

import { deserializer } from '~services/SearchStore/serializers';
import { companyDeserializer } from '~services/Auth/serializers';
import SearchStoreService from '~services/SearchStore/service';
import AuthStoreService from '~services/Auth/service';
import CallCenterService from '~services/Callcenter/service';
import { actionCreators as modalActions } from '~redux/Modal/actions';
import { ADD_ADDRESS_MODAL, CHANGE_ADDRESS } from '~redux/Modal/constants';
import { actionCreators as homeActions } from '~screens/Dashboard/screens/Home/redux/actions';
import LocalStorageService from '~services/LocalStorageService';
import { DISPATCH_METHODS } from '~constants/address';
import { actionCreators as authActions } from '~redux/Auth/actions';
import { DEFAULT_STORE_ID, RENDER_CALL_CENTER } from '~constants/environment';

import { parseStoresAndHotspots, getSubsidiary } from './utils';
import {
  NEAR_TARGET,
  CURRENT_SUBSIDIARY_TARGET,
  DELIVERY_TARGET,
  DISPATCH_METHOD_TARGET,
  DISPATCH_TYPE_TAB_TARGET,
  USER_ADDRESS,
  USER_ADRESSES,
  USED_COUPONS,
  STORES_TARGET,
  EXTERNAL_STORE_TARGET,
  COMPANIES_TARGET,
  CREATE_USER_ADDRESSES,
  CURRENT_STORE_INFO,
  ACTUAL_RECEIPT_COMPANY,
  SELECTED_DELIVERY_TIME,
  QUANTITY_NEAR_STORES,
  GO_TO_CHECKOUT_FROM_ADDRESS
} from './constants';

export const actions = createTypes(
  completeTypes(
    [
      'PICK_UP',
      'DELIVERY',
      'GET_USER_ADDRESS',
      'GET_USER_ADDRESSES',
      'GET_STORES',
      'GET_NEAR',
      'GET_USED_COUPONS',
      'GET_SUBSIDIARY',
      'GET_COMPANIES',
      'CREATE_COMPANY',
      'UPDATE_COMPANY',
      'DELETE_COMPANY',
      'CREATE_USER_ADDRESSES'
    ],
    ['SET_VALUE']
  ),
  '@@SEARCH_LOCAL'
);

const privateActionCreators = {
  getStores: (params, type, target, service) => ({
    type,
    target,
    service,
    payload: { latitude: params.lat, longitude: params.lng, page: params.page },
    successSelector: response => deserializer.serialize(response.data),
    injections: [withPostFailure(dispatch => dispatch({ type: actions.SET_VALUE, target, payload: null }))]
  }),
  getNear: (params, type, target, service) => ({
    type,
    target,
    service,
    // eslint-disable-next-line camelcase
    payload: { latitude: params.lat, longitude: params.lng, quantity_near_stores: QUANTITY_NEAR_STORES },
    successSelector: response => parseStoresAndHotspots(deserializer.serialize(response.data)),
    injections: [withPostFailure(dispatch => dispatch({ type: actions.SET_VALUE, target, payload: null }))]
  })
};

export const actionCreators = {
  setExternalStore: id => ({
    type: actions.SET_VALUE,
    target: EXTERNAL_STORE_TARGET,
    payload: id
  }),
  getStoresLocation: params =>
    privateActionCreators.getStores(params, actions.GET_STORES, STORES_TARGET, SearchStoreService.getStores),
  getNear: position =>
    privateActionCreators.getNear(position, actions.GET_NEAR, NEAR_TARGET, SearchStoreService.getNear),
  getStoreDelivery: (position, { formattedAddress, textAddress, commune, region }) => ({
    type: actions.DELIVERY,
    target: DELIVERY_TARGET,
    service: SearchStoreService.getStoreDelivery,
    payload: {
      latitude: position.lat,
      longitude: position.lng,
      page: position.page,
      formatted_address: formattedAddress,
      text_address: textAddress,
      commune,
      region
    },
    successSelector: response => deserializer.serialize(response.data),
    injections: [
      withPostSuccess((dispatch, response, state) => {
        const responseSubsidiary = deserializer.serialize(response.data);
        const { currentSubsidiary, dispatchType } = state.searchStore;
        if (
          DISPATCH_METHODS.PJ_DELIVERY === dispatchType &&
          responseSubsidiary?.id !== currentSubsidiary?.id
        ) {
          dispatch(actionCreators.selectSubsidiary(responseSubsidiary, false));
        }
      }),
      withPostFailure((dispatch, _, state) => {
        const { currentSubsidiary, dispatchType } = state.searchStore;
        if (DISPATCH_METHODS.PJ_DELIVERY === dispatchType && currentSubsidiary && !RENDER_CALL_CENTER) {
          dispatch({ type: actions.SET_VALUE, DELIVERY_TARGET, payload: null });
          dispatch(actionCreators.refreshSubsidiary(DEFAULT_STORE_ID));
          dispatch(authActions.clearAddress());
          dispatch(modalActions.openModal(CHANGE_ADDRESS));
        }
      })
    ]
  }),

  selectSubsidiary:
    (subsidiary, shouldClearCart = true) =>
    dispatch => {
      LocalStorageService.setCurrentSubsidiary(subsidiary);
      if (shouldClearCart) {
        dispatch(homeActions.clearCart());
      }
      dispatch({
        type: actions.SET_VALUE,
        target: CURRENT_SUBSIDIARY_TARGET,
        payload: subsidiary
      });
    },
  resetSubsidiary: () => dispatch => {
    LocalStorageService.removeCurrentSubsidiary();
    dispatch({
      type: actions.SET_VALUE,
      target: CURRENT_SUBSIDIARY_TARGET,
      payload: null
    });
  },
  refreshSubsidiary: (id, dispatchType, currentSubsidiary) => ({
    type: actions.GET_SUBSIDIARY,
    target: CURRENT_SUBSIDIARY_TARGET,
    service: SearchStoreService.getStore,
    payload: { id, dispatchMethod: dispatchType },
    successSelector: response => getSubsidiary(response, currentSubsidiary),
    injections: [
      withPreFetch(dispatch => {
        const prevSubsidiary = LocalStorageService.getCurrentSubsidiary();
        if (prevSubsidiary && prevSubsidiary.id !== id) {
          dispatch(homeActions.clearRedeemCode());
        }
      }),
      withSuccess((dispatch, response) => {
        dispatch(actionCreators.selectSubsidiary(getSubsidiary(response, currentSubsidiary), false));
        if (dispatchType) {
          let dispatchMethodType = dispatchType;
          if (currentSubsidiary?.hotspot && !currentSubsidiary?.papacar) {
            dispatchMethodType = DISPATCH_METHODS.PJ_DELIVERY;
          } else if (currentSubsidiary?.papacar) {
            dispatchMethodType = DISPATCH_METHODS.IN_STORE;
          }
          dispatch(actionCreators.setDispatchType(dispatchMethodType, currentSubsidiary));
        }
      })
    ]
  }),
  setDispatchType: dispatchType => dispatch => {
    LocalStorageService.setDispatchType(dispatchType);
    dispatch({
      type: actions.SET_VALUE,
      target: DISPATCH_METHOD_TARGET,
      payload: dispatchType
    });
    dispatch(homeActions.getBannerImages());
  },
  setDispatchTypeTab: dispatchType => ({
    type: actions.SET_VALUE,
    target: DISPATCH_TYPE_TAB_TARGET,
    payload: dispatchType
  }),
  setAddress: address => ({
    type: actions.SET_VALUE,
    target: USER_ADDRESS,
    payload: address
  }),
  setAddresses: address => ({
    type: actions.SET_VALUE,
    target: USER_ADRESSES,
    payload: address
  }),
  setCurrentStoreInfo: info => ({
    type: actions.SET_VALUE,
    target: CURRENT_STORE_INFO,
    payload: info
  }),
  getUserAddress: () => ({
    type: actions.GET_USER_ADDRESS,
    target: USER_ADRESSES,
    service: SearchStoreService.getUserAddress,
    successSelector: response =>
      deserializer.serialize(response.data).map(address => ({ ...address, hasStreetNumber: true }))
  }),
  getCallCenterUserAddress: externalId => ({
    type: actions.GET_USER_ADDRESSES,
    target: USER_ADRESSES,
    service: CallCenterService.getUserAddress,
    payload: externalId,
    injections: [
      withPostSuccess((dispatch, response) => {
        dispatch(
          actionCreators.setAddresses(
            deserializer.serialize(response.data).map(address => ({ ...address, hasStreetNumber: true }))
          )
        );
      })
    ]
  }),
  clearUserAddresses: () => ({
    type: actions.SET_VALUE,
    target: USER_ADRESSES
  }),
  createUserAddress: address => ({
    type: actions.CREATE_USER_ADDRESS,
    service: AuthStoreService.createAddress,
    payload: address,
    injections: [
      withSuccess(dispatch => {
        dispatch(modalActions.closeModal(ADD_ADDRESS_MODAL));
        dispatch(actionCreators.getUserAddress());
      })
    ]
  }),
  createCallCenterUserAddress: ({ externalId, address }) => ({
    type: actions.CREATE_USER_ADDRESSES,
    target: CREATE_USER_ADDRESSES,
    service: CallCenterService.createUserAddress,
    payload: { externalId, address },
    injections: [
      withPostSuccess(dispatch => {
        dispatch(modalActions.closeModal(ADD_ADDRESS_MODAL));
        dispatch(actionCreators.getCallCenterUserAddress(externalId));
      })
    ]
  }),
  editUserAddress: (address, id, { closeModal = true } = {}) => ({
    type: actions.UPDATE_USER_ADDRESS,
    service: AuthStoreService.updateAddress,
    payload: { address, id },
    injections: [
      withSuccess(dispatch => {
        if (closeModal) {
          dispatch(modalActions.closeModal(ADD_ADDRESS_MODAL));
        }
        dispatch(actionCreators.getUserAddress());
      })
    ]
  }),
  editCallCenterUserAddress: ({ externalId, address, id }) => ({
    type: actions.UPDATE_USER_ADDRESSES,
    service: CallCenterService.updateUserAddress,
    payload: { externalId, address, id },
    injections: [
      withSuccess(dispatch => {
        dispatch(modalActions.closeModal(ADD_ADDRESS_MODAL));
        dispatch(actionCreators.getCallCenterUserAddress(externalId));
      })
    ]
  }),
  deleteUserAddress: id => ({
    type: actions.DELETE_USER_ADDRESS,
    service: AuthStoreService.deleteAddress,
    payload: id,
    injections: [
      withSuccess(dispatch => {
        dispatch(modalActions.closeModal(ADD_ADDRESS_MODAL));
        dispatch(actionCreators.getUserAddress());
      })
    ]
  }),
  deleteCallCenterUserAddress: ({ externalId, id }) => ({
    type: actions.DELETE_USER_ADDRESS,
    service: CallCenterService.deleteUserAddress,
    payload: { externalId, id },
    injections: [
      withSuccess(dispatch => {
        dispatch(modalActions.closeModal(ADD_ADDRESS_MODAL));
        dispatch(actionCreators.getCallCenterUserAddress(externalId));
      })
    ]
  }),
  getUsedCoupons: () => ({
    type: actions.GET_USED_COUPONS,
    service: SearchStoreService.getUsedCoupons,
    target: USED_COUPONS,
    successSelector: response => deserializer.serialize(response.data.page)
  }),
  setValue: (target, payload) => ({
    type: actions.SET_VALUE,
    target,
    payload
  }),
  setSelectedDeliveryTime: data => ({
    type: actions.SET_VALUE,
    target: SELECTED_DELIVERY_TIME,
    payload: data
  }),
  setGoToCheckoutFromAddress: data => ({
    type: actions.SET_VALUE,
    target: GO_TO_CHECKOUT_FROM_ADDRESS,
    payload: data
  }),
  // companies start
  clearUserCompanies: () => ({
    type: actions.SET_VALUE,
    target: COMPANIES_TARGET
  }),
  getCompanies: externalId => ({
    type: actions.GET_COMPANIES,
    service: AuthStoreService.getCompanies,
    payload: externalId,
    target: COMPANIES_TARGET,
    successSelector: response => companyDeserializer.serialize(response.data)
  }),
  saveCompany: (company, externalId) => ({
    type: actions.UPDATE_COMPANY,
    service: AuthStoreService.saveCompany,
    payload: { company, externalId },
    injections: [
      withSuccess(dispatch => {
        dispatch(actionCreators.getCompanies(externalId));
      })
    ]
  }),
  deleteCompany: (id, externalId) => ({
    type: actions.DELETE_COMPANY,
    service: AuthStoreService.deleteCompany,
    payload: { id, externalId },
    injections: [
      withSuccess(dispatch => {
        dispatch(actionCreators.getCompanies());
      })
    ]
  }),
  setSelectedCompanyForReceipt: data => ({
    type: actions.SET_VALUE,
    target: ACTUAL_RECEIPT_COMPANY,
    payload: data
  })
  // companies end
};
