/* eslint-disable max-lines */
import { iceCream, pint, pizza as pizzaIcon, pricetag } from 'ionicons/icons';
import { t } from 'i18next';

import { ALL_PIZZA_KINDS } from '~constants/products';
import { COUNTRIES } from '~constants/environment';
import dessertCl from 'src/app/assets/image_categories/chile/desserts.jpg';
import dessertClMobile from 'src/app/assets/image_categories/chile/dessertsMobile.jpg';
import drinksCl from 'src/app/assets/image_categories/chile/drinks.jpg';
import drinksClMobile from 'src/app/assets/image_categories/chile/drinksMobile.jpg';
import extrasCl from 'src/app/assets/image_categories/chile/extras.jpg';
import extrasClMobile from 'src/app/assets/image_categories/chile/extrasMobile.jpg';
import pizzasCl from 'src/app/assets/image_categories/chile/pizzas.jpg';
import pizzasClMobile from 'src/app/assets/image_categories/chile/pizzasMobile.jpg';
import promosCl from 'src/app/assets/image_categories/chile/promos.jpg';
import promosClMobile from 'src/app/assets/image_categories/chile/promosMobile.jpg';
import sidesCl from 'src/app/assets/image_categories/chile/sides.jpg';
import sidesClMobile from 'src/app/assets/image_categories/chile/sidesMobile.jpg';
import dessertCa from 'src/app/assets/image_categories/ca/desserts.jpg';
import dessertCaMobile from 'src/app/assets/image_categories/ca/dessertsMobile.jpg';
import drinksCa from 'src/app/assets/image_categories/ca/drinks.jpg';
import drinksCaMobile from 'src/app/assets/image_categories/ca/drinksMobile.jpg';
import extrasCa from 'src/app/assets/image_categories/ca/extras.jpg';
import extrasCaMobile from 'src/app/assets/image_categories/ca/extrasMobile.jpg';
import pizzasCa from 'src/app/assets/image_categories/ca/pizzas.jpg';
import pizzasCaMobile from 'src/app/assets/image_categories/ca/pizzasMobile.jpg';
import promosCa from 'src/app/assets/image_categories/ca/promos.jpg';
import promosCaMobile from 'src/app/assets/image_categories/ca/promosMobile.jpg';
import sidesCa from 'src/app/assets/image_categories/ca/sides.jpg';
import sidesCaMobile from 'src/app/assets/image_categories/ca/sidesMobile.jpg';
import dessertEs from 'src/app/assets/image_categories/spain/desserts.jpg';
import dessertEsMobile from 'src/app/assets/image_categories/spain/dessertsMobile.jpg';
import drinksEs from 'src/app/assets/image_categories/spain/drinks.jpg';
import drinksEsMobile from 'src/app/assets/image_categories/spain/drinksMobile.jpg';
import extrasEs from 'src/app/assets/image_categories/spain/extras.jpg';
import extrasEsMobile from 'src/app/assets/image_categories/spain/extrasMobile.jpg';
import pizzasEs from 'src/app/assets/image_categories/spain/pizzas.jpg';
import pizzasEsMobile from 'src/app/assets/image_categories/spain/pizzasMobile.jpg';
import promosEs from 'src/app/assets/image_categories/spain/promos.jpg';
import promosEsMobile from 'src/app/assets/image_categories/spain/promosMobile.jpg';
import sidesEs from 'src/app/assets/image_categories/spain/sides.jpg';
import sidesEsMobile from 'src/app/assets/image_categories/spain/sidesMobile.jpg';
import dessertPt from 'src/app/assets/image_categories/portugal/desserts.jpg';
import dessertPtMobile from 'src/app/assets/image_categories/portugal/dessertsMobile.jpg';
import drinksPt from 'src/app/assets/image_categories/portugal/drinks.jpg';
import drinksPtMobile from 'src/app/assets/image_categories/portugal/drinksMobile.jpg';
import extrasPt from 'src/app/assets/image_categories/portugal/extras.jpg';
import extrasPtMobile from 'src/app/assets/image_categories/portugal/extrasMobile.jpg';
import pizzasPt from 'src/app/assets/image_categories/portugal/pizzas.jpg';
import pizzasPtMobile from 'src/app/assets/image_categories/portugal/pizzasMobile.jpg';
import promosPt from 'src/app/assets/image_categories/portugal/promos.jpg';
import promosPtMobile from 'src/app/assets/image_categories/portugal/promosMobile.jpg';
import sidesPt from 'src/app/assets/image_categories/portugal/sides.jpg';
import sidesPtMobile from 'src/app/assets/image_categories/portugal/sidesMobile.jpg';

import { side, extra } from './icons-inline';

export const SCROLL_DEBOUNCE_TIME = 2;
export const MOVE_ELEMENT_DEBOUNCE_TIME = 50;

export const HEIGHT_DIFFERENCE_SCROLL = 150;

export const PRODUCT_CATEGORIES_ICONS = {
  promo: pricetag,
  drink: pint,
  side,
  dessert: iceCream,
  extra,
  pizza: pizzaIcon
};

export const PRODUCT_TYPES = { pizza: 'Pizza', complement: 'Complement', offer: 'Offer' };

export const PRODUCT_CATEGORIES = {
  ...ALL_PIZZA_KINDS,
  DESSERT: 'dessert',
  SIDE: 'side',
  DRINK: 'drink',
  EXTRA: 'extra',
  PROMO: 'promo',
  BY_HALVES: 'by_halves',
  BYOP: 'byop',
  COMBOS: 'combos',
  SAUCE: 'sauce',
  TRADITIONAL: 'traditional',
  UPSELLING: 'upselling',
  CROSS_SELLING: 'crossSelling',
  OFFER: 'offer'
};

export const COUNTRIES_WITH_CHEAPEST_SIZE = [COUNTRIES.es, COUNTRIES.pt];

export const KEY_ID = 'id-menu-action-';

export const MATRIX_CATEGORIES_IMAGES = {
  chile: [
    {
      id: 'promos',
      image: {
        desktop: promosCl,
        mobile: promosClMobile
      },
      name: t('menu:promo'),
      targetClick: () => document.getElementById('nav-sectionmenu-promo')?.click()
    },
    {
      id: 'pizzas',
      image: {
        desktop: pizzasCl,
        mobile: pizzasClMobile
      },
      name: t('menu:pizza'),
      targetClick: () => document.getElementById('nav-sectionmenu-pizza')?.click()
    },
    {
      id: 'sides',
      image: {
        desktop: sidesCl,
        mobile: sidesClMobile
      },
      name: t('menu:side'),
      targetClick: () => document.getElementById('nav-sectionmenu-side')?.click()
    },
    {
      id: 'drinks',
      image: {
        desktop: drinksCl,
        mobile: drinksClMobile
      },
      name: t('menu:drink'),
      targetClick: () => document.getElementById('nav-sectionmenu-drink')?.click()
    },
    {
      id: 'desserts',
      image: {
        desktop: dessertCl,
        mobile: dessertClMobile
      },
      name: t('menu:dessert'),
      targetClick: () => document.getElementById('nav-sectionmenu-dessert')?.click()
    },
    {
      id: 'extras',
      image: {
        desktop: extrasCl,
        mobile: extrasClMobile
      },
      name: t('menu:extra'),
      targetClick: () => document.getElementById('nav-sectionmenu-extra')?.click()
    }
  ],
  ca: [
    {
      id: 'promos',
      image: {
        desktop: promosCa,
        mobile: promosCaMobile
      },
      name: t('menu:promo'),
      targetClick: () => document.getElementById('nav-sectionmenu-promo')?.click()
    },
    {
      id: 'pizzas',
      image: {
        desktop: pizzasCa,
        mobile: pizzasCaMobile
      },
      name: t('menu:pizza'),
      targetClick: () => document.getElementById('nav-sectionmenu-pizza')?.click()
    },
    {
      id: 'sides',
      image: {
        desktop: sidesCa,
        mobile: sidesCaMobile
      },
      name: t('menu:side'),
      targetClick: () => document.getElementById('nav-sectionmenu-side')?.click()
    },
    {
      id: 'drinks',
      image: {
        desktop: drinksCa,
        mobile: drinksCaMobile
      },
      name: t('menu:drink'),
      targetClick: () => document.getElementById('nav-sectionmenu-drink')?.click()
    },
    {
      id: 'desserts',
      image: {
        desktop: dessertCa,
        mobile: dessertCaMobile
      },
      name: t('menu:dessert'),
      targetClick: () => document.getElementById('nav-sectionmenu-dessert')?.click()
    },
    {
      id: 'extras',
      image: {
        desktop: extrasCa,
        mobile: extrasCaMobile
      },
      name: t('menu:extra'),
      targetClick: () => document.getElementById('nav-sectionmenu-extra')?.click()
    }
  ],
  spain: [
    {
      id: 'promos',
      image: {
        desktop: promosEs,
        mobile: promosEsMobile
      },
      name: t('menu:promo'),
      targetClick: () => document.getElementById('nav-sectionmenu-promo')?.click()
    },
    {
      id: 'pizzas',
      image: {
        desktop: pizzasEs,
        mobile: pizzasEsMobile
      },
      name: t('menu:pizza'),
      targetClick: () => document.getElementById('nav-sectionmenu-pizza')?.click()
    },
    {
      id: 'sides',
      image: {
        desktop: sidesEs,
        mobile: sidesEsMobile
      },
      name: t('menu:side'),
      targetClick: () => document.getElementById('nav-sectionmenu-side')?.click()
    },
    {
      id: 'drinks',
      image: {
        desktop: drinksEs,
        mobile: drinksEsMobile
      },
      name: t('menu:drink'),
      targetClick: () => document.getElementById('nav-sectionmenu-drink')?.click()
    },
    {
      id: 'desserts',
      image: {
        desktop: dessertEs,
        mobile: dessertEsMobile
      },
      name: t('menu:dessert'),
      targetClick: () => document.getElementById('nav-sectionmenu-dessert')?.click()
    },
    {
      id: 'extras',
      image: {
        desktop: extrasEs,
        mobile: extrasEsMobile
      },
      name: t('menu:extra'),
      targetClick: () => document.getElementById('nav-sectionmenu-extra')?.click()
    }
  ],
  portugal: [
    {
      id: 'promos',
      image: {
        desktop: promosPt,
        mobile: promosPtMobile
      },
      name: t('menu:promo'),
      targetClick: () => document.getElementById('nav-sectionmenu-promo')?.click()
    },
    {
      id: 'pizzas',
      image: {
        desktop: pizzasPt,
        mobile: pizzasPtMobile
      },
      name: t('menu:pizza'),
      targetClick: () => document.getElementById('nav-sectionmenu-pizza')?.click()
    },
    {
      id: 'sides',
      image: {
        desktop: sidesPt,
        mobile: sidesPtMobile
      },
      name: t('menu:side'),
      targetClick: () => document.getElementById('nav-sectionmenu-side')?.click()
    },
    {
      id: 'drinks',
      image: {
        desktop: drinksPt,
        mobile: drinksPtMobile
      },
      name: t('menu:drink'),
      targetClick: () => document.getElementById('nav-sectionmenu-drink')?.click()
    },
    {
      id: 'desserts',
      image: {
        desktop: dessertPt,
        mobile: dessertPtMobile
      },
      name: t('menu:dessert'),
      targetClick: () => document.getElementById('nav-sectionmenu-dessert')?.click()
    },
    {
      id: 'extras',
      image: {
        desktop: extrasPt,
        mobile: extrasPtMobile
      },
      name: t('menu:extra'),
      targetClick: () => document.getElementById('nav-sectionmenu-extra')?.click()
    }
  ]
};

export const MENU_CATEGORIES_IMAGES = {
  [COUNTRIES.cl]: MATRIX_CATEGORIES_IMAGES.chile,
  [COUNTRIES.cr]: MATRIX_CATEGORIES_IMAGES.ca,
  [COUNTRIES.gt]: MATRIX_CATEGORIES_IMAGES.ca,
  [COUNTRIES.pa]: MATRIX_CATEGORIES_IMAGES.ca,
  [COUNTRIES.es]: MATRIX_CATEGORIES_IMAGES.spain,
  [COUNTRIES.pt]: MATRIX_CATEGORIES_IMAGES.portugal
};
