import i18next from 'i18next';

i18next.addResources('es_CL', 'Footer', {
  titleGroup1: 'Papa John’s',
  aboutUs: 'Acerca de nosotros',
  workWithUs: 'Trabaja con Nosotros',
  ingredients: 'Ingredientes',
  companyInternational: "Papa John's Int.",
  titleGroup2: 'Ventas por mayor',
  foodtruck: 'Foodtruck',
  corporateSales: 'Ventas corporativas',
  corporateEvents: 'Eventos corporativos',
  celebrateYourBirthday: 'Celebra tu cumpleaños',
  titleGroup3: 'Ayuda',
  helpCenter: 'Preguntas frecuentes',
  contact: 'Contacto',
  ticket: 'Consulta tu boleta',
  allergens: 'Alérgenos',
  nutritionalInformation: 'Información nutricional',
  titleGroup4: 'Legal',
  conditions: 'Términos y Condiciones',
  cookiesPolicy: 'Política de cookies',
  privacyPolicy: 'Política de privacidad',
  ethicalCode: 'Codigo Ético',
  ethicalChannel: 'Canal Ético',
  complaintsBook: 'Libro de reclamos',
  titleGroup5: 'Menú',
  storesLocation: 'Nuestros locales',
  titleGroup6: 'Papa Puntos',
  joinTheProgram: 'Únete al programa',
  textCopyright: "© {{year}}  Papa John's Internacional, Inc. Todos los derechos reservados",
  followUs: 'Síguenos en:',
  freeChickens: 'Comunicado de gallinas libres',
  betterWorld: 'Mejor Mundo',
  cookieSettings: 'Configuración de cookies',
  instagramLink: 'ir al perfil de papajohns en Instagram',
  twitterLink: 'ir al perfil de papajohns en Twitter',
  facebookLink: 'ir al perfil de papajohns en Facebook',
  tiktokLink: 'ir al perfil de papajohns en Tiktok',
  pizzas: 'Pizzas',
  blog: 'Blog',
  sacNumber: 'Pide por teléfono 600 390 5000',
  promos: 'Promociones',
  sides: 'Entrantes',
  drinks: 'Bebidas',
  desserts: 'Postres',
  extras: 'Extras',
  promosPath: '/promociones/',
  pizzasPath: '/pizzas/',
  sidesPath: '/acompanamientos/',
  drinksPath: '/bebidas/',
  dessertsPath: '/postres/',
  extrasPath: '/extras/'
});

i18next.addResources('es_ES', 'Footer', {
  titleGroup1: 'Papa Johns',
  aboutUs: 'Acerca de nosotros',
  workWithUs: 'Trabaja con Nosotros',
  ingredients: 'Ingredientes',
  companyInternational: "Papa John's Int.",
  titleGroup2: 'Calidad y Producto',
  foodtruck: 'Foodtruck',
  corporateSales: 'Ventas corporativas',
  corporateEvents: 'Eventos corporativos',
  celebrateYourBirthday: 'Celebra tu cumpleaños',
  titleGroup3: 'Calidad y producto',
  helpCenter: 'Preguntas frecuentes',
  contact: 'Contacto & Atención al Cliente',
  ticket: 'Consulta tu boleta',
  allergens: 'Listado de alérgenos',
  nutritionalInformation: 'Valores nutricionales',
  glutenFree: 'Sin Gluten',
  titleGroup4: 'Legal',
  conditions: ' Condiciones generales de venta',
  cookiesPolicy: 'Política de cookies',
  privacyPolicy: 'Política de privacidad',
  titleGroup5: 'Carta',
  storesLocation: 'Nuestros locales',
  titleGroup6: 'Papa Puntos',
  textCopyright: "© {{year}}  Papa John's Internacional, Inc. Todos los derechos reservados",
  followUs: 'Síguenos en:',
  franchises: 'Franquicias',
  cookieSettings: 'Configuración de cookies',
  titleGroup7: 'Franquicias',
  textInformationFranchises: 'Información',
  textContactFormFranchises: 'Formulario de contacto',
  legalDisclaimer: 'Aviso legal',
  blog: 'Blog',
  complaintsChannel: 'Canal del informante',
  sides: 'Entrantes',
  sidesPath: '/entrantes/'
});

i18next.addResources('pt_PT', 'Footer', {
  titleGroup1: 'Papa Johns',
  aboutUs: 'Sobre nós',
  workWithUs: 'Trabalhe connosco',
  ingredients: 'Ingredientes',
  companyInternational: "Papa John's Int.",
  titleGroup2: 'Vendas por atacado',
  foodtruck: 'Foodtruck',
  corporateSales: 'Vendas corporativas',
  corporateEvents: 'Eventos corporativos',
  celebrateYourBirthday: 'Comemore seu aniversário',
  titleGroup3: 'Ajuda',
  helpCenter: 'Perguntas frequentes',
  contact: 'Entre em contato',
  ticket: 'Verifique seu ingresso',
  allergens: 'Alérgenos',
  nutritionalInformation: 'Informação nutricional',
  titleGroup4: 'Legal',
  conditions: 'Condições gerais de venda',
  cookiesPolicy: 'Política de cookies',
  privacyPolicy: 'Política de privacidade',
  ethicalCode: 'Código de ética',
  complaintsBook: 'Livro de reclamações electronico',
  titleGroup5: 'Menu',
  storesLocation: 'Nossas lojas',
  titleGroup6: 'Papa Pontos',
  textCopyright: "© {{year}}  Papa John's Internacional, Inc. Todos os direitos reservados",
  followUs: 'Siga-nos:',
  franchises: 'Franquias',
  cookieSettings: 'Configurações de cookies',
  titleGroup7: 'Franquias',
  textInformationFranchises: 'Informação',
  textContactFormFranchises: 'Formulário de contacto',
  instagramLink: 'vá para o perfil dos papajohns em Instagram',
  twitterLink: 'vá para o perfil dos papajohns em Twitter',
  facebookLink: 'vá para o perfil dos papajohns em Facebook',
  tiktokLink: 'vá para o perfil dos papajohns em Tik tok',
  blog: 'Blog',
  complaintsChannel: 'Canal de informantes',
  promos: 'Promoções',
  sides: 'Entradas',
  drinks: 'Bebidas',
  desserts: 'Sobremesas',
  extras: 'Extras',
  promosPath: '/promocoes/',
  pizzasPath: '/pizzas/',
  sidesPath: '/entradas/',
  drinksPath: '/bebidas/',
  dessertsPath: '/sobremesas/',
  extrasPath: '/extras/'
});

i18next.addResources('pt_PT', 'ComplaintsBook', {
  pagePath: 'https://www.livroreclamacoes.pt/',
  pageTitle: 'Livro de reclamações electronico',
  pageDescription: 'Livro de reclamações electronico'
});
