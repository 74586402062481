/* eslint-disable prefer-rest-params */
/* eslint-disable camelcase */
/* eslint-disable max-lines */
import { DEFAULT_STORE_ID } from '~constants/environment';
import { BRAND, CALLCENTER_KEYWORDS } from '~constants/analytics';
import { CLIENT_PAGES } from '~constants/pages';
import { mapItemsToGA4Data } from '~models/shoppingCart';
import LocalStorageService from '~services/LocalStorageService';

import { getStringOrScript } from './string';

export const PLATFORM = {
  WEB: 'web',
  CALL_CENTER: 'call center'
};

export const EVENT = {
  confirmOrder: 'ConfirmOrder',
  confirmedOrder: 'ConfirmedOrder',
  failedOrder: 'FailedOrder',
  deliveryOutReach: 'location',
  purchase: 'purchase',
  purchaseCC: 'purchaseCC',
  addToCart: 'addToCart',
  checkout: 'checkout',
  viewItem: 'view_item',
  beginCheckout: 'begin_checkout',
  addToCartGA4: 'add_to_cart',
  removeFromCart: 'remove_from_cart',
  addPaymentInfo: 'add_payment_info',
  promoImpressions: 'promoImpressions',
  promotionClick: 'promotionClick',
  promoselling: 'promoselling',
  eventPJ: 'eventPJ',
  SHOW_UPSELLING: 'showUpselling',
  ADD_UPSELLING: 'addUpselling',
  REMOVE_UPSELLING: 'removeUpselling',
  PAGE_UPDATE: 'page-update',
  USE_GIFT_CARD: 'useGiftCard'
};
export const eventCategory = {
  microconversion: 'microconversion',
  interaction: 'interaction',
  abandonedCart: 'abandonedCart'
};
export const eventLabel = {
  lastOrder: 'lastOrder',
  closeConfigurationPromo: 'close configuration promo',
  closePizzaByHalves: 'close pizza by halves',
  pizzaByHalves: 'pizza by halves',
  closecrossSelling: 'close crossSelling',
  newRegister: 'new register',
  phone: 'phone',
  newRegisterVerification: 'new register verification',
  personalize: 'personalize',
  removeProduct: 'remove product',
  backPromo: 'back promo',
  buyNow: 'buy now',
  addToCart: 'add to cart',
  paymentMethodError: 'payment method error',
  storeClosed: 'store closed',
  edit: 'edit',
  menu: 'menu',
  openCartHome: 'open cart home'
};
export const eventAction = {
  validCoupon: 'valid coupon',
  invalidCoupon: 'invalid coupon',
  openPopUp: 'open popup',
  closePopUp: 'close popup',
  copyCouponCode: 'copy coupon code',
  exchangePapaPoints: 'exchange papa points',
  profileAccess: 'profile access',
  promoConfig: 'configuration promo',
  closeCustomPizza: 'close custom pizza',
  pizzaByHalves: 'pizza by halves',
  modalcrossSelling: 'modal crossSelling',
  validRegister: 'valid register',
  invalidRegister: 'invalid register',
  orderBuilder: 'order builder',
  paymentError: 'payment error',
  purchaseError: 'purchase error',
  storeError: 'store error',
  orderError: 'order error',
  giftCard: 'gift card',
  configuration: 'configuracion',
  sinpeMovil: 'sinpe movil',
  openCartHome: 'open cart home',
  cartAbandonment: 'cart abandonment'
};

export const DIMENSIONS = {
  cart: 'cart',
  menu: 'menu',
  crossSelling: 'crossSelling',
  upselling: 'upselling',
  giftcard: 'giftcard'
};

const promoImpressionEvent = payload => {
  const { id, name, creative, position } = payload;
  const promoImpressionsPayload = {
    id,
    name,
    creative: creative || '-',
    position
  };
  const promoViewPayload = {
    promoView: {
      promotions: promoImpressionsPayload
    }
  };

  return promoViewPayload;
};

const promotionClickEvent = payload => {
  const { id, name, product, index } = payload;
  const promotionClickPayload = {
    id,
    name,
    creative: product || '-',
    position: index
  };
  const promoClickPayload = {
    promoClick: {
      promotions: promotionClickPayload
    }
  };

  return promoClickPayload;
};

const checkoutEvent = payload => {
  const checkoutPayload = {
    checkout: {
      actionField: { step: 1 },
      products: payload
    }
  };

  return checkoutPayload;
};

const purchaseEvent = (payload, event) => {
  const { data, userEmail, isAuthenticated } = payload;

  const isCallCenterAgent =
    !isAuthenticated &&
    (userEmail.includes(CALLCENTER_KEYWORDS.agent) ||
      userEmail.includes(CALLCENTER_KEYWORDS.agentCallCenter));

  const payloadEvent = isCallCenterAgent ? EVENT.purchaseCC : event;

  return [data, payloadEvent];
};

const orderStatusEvent = (payload, orderEvent) => {
  const { total, currency, discount, currentTip, dispatchMethod, items } = payload;
  const orderStatusPayload = {
    [orderEvent]: {
      actionField: {
        revenue: total,
        currency,
        discount,
        tip: currentTip,
        dispatch: dispatchMethod,
        // eslint-disable-next-line camelcase
        product_counter: items.length
      }
    }
  };
  return [orderStatusPayload, orderEvent];
};

const interactionEvent = payload => {
  const { eventActionCategory, action, label } = payload;
  const interactionPayload = {
    eventCategory: eventActionCategory,
    eventAction: action,
    eventLabel: label
  };
  return interactionPayload;
};

const defaultEvent = (payload, event) => {
  const defaultPayload = {
    defaultEvent: {
      actionField: {
        ...payload
      }
    }
  };
  return [defaultPayload, event || 'defaultEvent'];
};

const payloadEventSelector = (event, data) => {
  if (event === EVENT.promoImpressions) {
    return [promoImpressionEvent(data), event];
  }
  if (event === EVENT.promotionClick) {
    return [promotionClickEvent(data), event];
  }
  if (
    [
      EVENT.viewItem,
      EVENT.addToCart,
      EVENT.beginCheckout,
      EVENT.addToCartGA4,
      EVENT.addPaymentInfo,
      EVENT.removeFromCart
    ].includes(event)
  ) {
    return [data, event];
  }
  if (event === EVENT.checkout) {
    return [checkoutEvent(data), event];
  }
  if (event === EVENT.deliveryOutReach) {
    return [data, event];
  }
  if (event === EVENT.purchase) {
    const [payload, payloadEvent] = purchaseEvent(data, event);
    return [payload, payloadEvent];
  }
  if ([EVENT.confirmOrder, EVENT.confirmedOrder, EVENT.failedOrder].includes(event)) {
    const [payload, payloadEvent] = orderStatusEvent(data, event);
    return [payload, payloadEvent];
  }
  if (event === EVENT.eventPJ) {
    return [interactionEvent(data), event];
  }
  const [payload, payloadEvent] = defaultEvent(data, event);
  return [payload, payloadEvent];
};

const createDataLayerPayload = (event, payload) => {
  const { coupon, ...restPayload } = payload;
  return {
    event,
    ecommerce: {
      ...restPayload,
      ...(coupon && { coupon: coupon.code })
    }
  };
};

export const sendGTMEvent = (event, data) => {
  const [payload, GTMEvent] = payloadEventSelector(event, data);
  if (!window.dataLayer) {
    return;
  }

  const dataLayerPayload = createDataLayerPayload(GTMEvent, payload);
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    ...dataLayerPayload
  });
};

export const sendGTMAddtoCartItemsEvent = (items, currency, value) => {
  sendGTMEvent(EVENT.addToCartGA4, {
    currency,
    value,
    items: mapItemsToGA4Data(items)
  });
};

export const sendGTMremoveFromCartEvent = (items, currency, value) => {
  sendGTMEvent(EVENT.removeFromCart, {
    currency,
    value,
    items: mapItemsToGA4Data(items)
  });
};

export const sendGTMAddtoCartEvent = item => {
  const dimmension =
    DIMENSIONS[
      window.location.pathname === CLIENT_PAGES.CHECKOUT.path ? DIMENSIONS.crossSelling : DIMENSIONS.menu
    ];
  sendGTMEvent(EVENT.addToCart, {
    add: {
      products: {
        name: item.name,
        id: String(item.id),
        category: getStringOrScript(item.category),
        variant: getStringOrScript(item.variant),
        brand: BRAND,
        dimension9: dimmension,
        quantity: item?.quantity || 1,
        price: item?.price
      }
    },
    currency: item.currency
  });
  sendGTMEvent(EVENT.addToCartGA4, {
    items: mapItemsToGA4Data([item]),
    currency: item.currency,
    value: item?.price
  });
};

const getClientId = () => {
  try {
    if (document.cookie.indexOf('_ga=') > -1) {
      const gaCookie = document.cookie.split('_ga=')[1].split(';')[0].split('.');
      // eslint-disable-next-line no-magic-numbers
      return `${gaCookie[2]}.${gaCookie[3]}`;
    }
    return '-';
  } catch (e) {
    return '-';
  }
};

export const getCategoriesConcat = items =>
  items.reduce((acum, item) => `${acum}-${item?.category || item?.offerType}`, '');

export const sendDataLayer = ({
  event,
  pageName = '',
  userId = '',
  platform = 'web',
  isLogged = false,
  categories = '',
  quantity = 0,
  storeName,
  storeId = false
}) => {
  window.dataLayer.push({
    event,
    'path-update': pageName,
    pageName,
    clientId: getClientId(),
    userid: userId,
    platform,
    login: isLogged,
    categoriesProducts: categories,
    quantity,
    storeName: storeId ? storeName : '',
    storeId: storeId ? storeId : DEFAULT_STORE_ID
  });
};

function gtag() {
  window.dataLayer.push(arguments);
}

const grantedOrDenied = granted => (granted ? 'granted' : 'denied');

const setBaseDataConsentMode = (analyticsCookie, marketingCookie) => ({
  ad_user_data: grantedOrDenied(marketingCookie),
  ad_personalization: grantedOrDenied(marketingCookie),
  ad_storage: grantedOrDenied(marketingCookie),
  analytics_storage: grantedOrDenied(analyticsCookie)
});

export const setConsentMode = ({ analyticsCookie, marketingCookie }) => {
  const userAcceptCookies = LocalStorageService.getCookiesAccept();
  const analytics = userAcceptCookies?.analyticsCookie || analyticsCookie;
  const marketing = userAcceptCookies?.marketingCookie || marketingCookie;
  const isGranted = analytics || marketing;

  const consentConfig = {
    ...setBaseDataConsentMode(analytics, marketing),
    ...(isGranted ? {} : { wait_for_update: 500 })
  };

  return gtag('consent', isGranted ? 'update' : 'default', consentConfig);
};
