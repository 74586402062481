import { Helmet } from 'react-helmet';
import TagManager from 'react-gtm-module';
import { t } from 'i18next';

import { startGA } from '~services/Analytics';
import { GOOGLE_OPTIMIZE_SCRIPT, ANTI_FLICKER_SCRIPT, PUSH_ALERT } from '~utils/scripts';
import { GOOGLE_OPTIMIZE_ENABLED, PUSH_ALERT_ENABLED } from '~constants/features';
import { isCountryAvaibleToShowWeglot } from '~utils/weglot';
import { WEGLOT_LIB } from '~utils/weglot-lib';
import { BASE_URL, TRANSLATION_KEY } from '~constants/environment';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_WEBSITE_NAME
};

export const renderHeadAnalyticsScripts = () => (
  <Helmet>
    {GOOGLE_OPTIMIZE_ENABLED && <script>{GOOGLE_OPTIMIZE_SCRIPT}</script>}
    {GOOGLE_OPTIMIZE_ENABLED && <script>{ANTI_FLICKER_SCRIPT}</script>}
  </Helmet>
);

export const renderJsonLDSeoScript = () => (
  <Helmet>
    <script type="application/ldtjson">
      {`"@context": "https://schema.org",
      "@type": "WebSite",
      "url": "${BASE_URL}",
      "description": "${t('App:SEOdescription')}",
      "inLenquage": "${TRANSLATION_KEY}"`}
    </script>
  </Helmet>
);

export const renderPushAlert = () => (
  <Helmet>
    {PUSH_ALERT_ENABLED && ['es'].includes(process.env.REACT_APP_ISO_COUNTRY) && (
      <script>{PUSH_ALERT}</script>
    )}
  </Helmet>
);

export const weglotScript = () => (
  <Helmet encodeSpecialCharacters>{isCountryAvaibleToShowWeglot && <script>{WEGLOT_LIB}</script>}</Helmet>
);

export const loadAnalyticsScripts = () => {
  startGA();
  TagManager?.initialize(tagManagerArgs);
};

export const hasAnalyticsConsent = () =>
  window.Cookiebot && window.Cookiebot.consents && window.Cookiebot.consents.analytics === true;
