/* eslint-disable max-params */
import { onReadValue, onSuccess } from 'redux-recompose';

import LocalStorageService from '~services/LocalStorageService';
import { buildPizzaVariantName, buildPizzaDescription } from '~utils/pizza';
import { buildOfferDescription } from '~utils/offers';
import { PRODUCT_CATEGORIES } from '~screens/Dashboard/screens/Home/components/Menu/constants';
import { SECONDS_IN_MILLISECONDS } from '~components/CustomizableOption/constants';
import { productTypes } from '~constants/products';
import { PICTURE_SIZES, getPhotoThumbnail, getVideoUrl } from '~utils/images';

import {
  SHOPPING_CART_TARGET,
  OPTIMIZED_SHOPPING_CART_TARGET,
  BANNER_IMAGES_TARGET,
  BANNER_IMAGE_TYPES
} from './constants';

const parsePizzaData = (pizza, options = {}, basePizzaData, state, sizesAndCrusts) => ({
  ...pizza,
  variantName: buildPizzaVariantName(pizza, sizesAndCrusts?.crusts),
  description: buildPizzaDescription(pizza, basePizzaData, options?.description?.hideBaseDescription),
  category: PRODUCT_CATEGORIES.PIZZA
});

const now = new Date();
const expirationDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 3, 0, 0);
const expire3am = (expirationDate - now) / (60 * 1000);

const parseComplementData = (complement, flattenedMenu) => {
  const UID = `${complement.menuableType}${complement.menuableId}`;
  const { side } = complement;
  const {
    data: { menuable }
  } = flattenedMenu[UID];
  const variantName = side ? side.name : menuable.dropdownText;

  return {
    ...complement,
    variantName,
    category: menuable.category
  };
};

const parseComplementVariantData = (complementVariant, flattenedMenu) => {
  const productUID = `${productTypes.COMPLEMENT}${complementVariant.productMenuableId}`;
  const product = flattenedMenu[productUID];

  return {
    ...complementVariant,
    category: product.data.menuable.category
  };
};

// eslint-disable-next-line max-params
const parseOfferData = (offer, flattenedOffers, flattenedMenu, basePizzaData, state) => {
  // eslint-disable-next-line no-use-before-define
  const parsedGroupProducts = parseProductsData(
    offer.groupProducts,
    flattenedMenu,
    flattenedOffers,
    {
      pizza: { description: { hideBaseDescription: true } }
    },
    basePizzaData,
    state
  );
  const parsedOffer = { ...offer, groupProducts: parsedGroupProducts };
  return {
    ...parsedOffer,
    description: buildOfferDescription(parsedOffer)
  };
};

// eslint-disable-next-line max-params
const parseProductsData = (
  products,
  flattenedMenu,
  flattenedOffers,
  options = {},
  basePizzaData,
  state,
  sizesAndCrusts
) =>
  products.map(product => {
    if (product.menuableType && product.menuableId) {
      if (product.menuableType === productTypes.PIZZA_VARIANT) {
        return parsePizzaData(product, options.pizza, basePizzaData, state, sizesAndCrusts);
      } else if (product.menuableType === productTypes.COMPLEMENT) {
        return parseComplementData(product, flattenedMenu);
      } else if (product.menuableType === productTypes.COMPLEMENT_VARIANT) {
        return parseComplementVariantData(product, flattenedMenu);
      }
    } else if (product.offerType && product.offerId) {
      return parseOfferData(product, flattenedOffers, flattenedMenu, basePizzaData);
    }

    return product;
  });

export const onGetCartPricesSuccess = onSuccess((action, state) => {
  const { flattenedMenu, flattenedCombos } = state;
  const shoppingCart = action.payload[SHOPPING_CART_TARGET];
  const { basePizzaData, sizesAndCrusts } = action.payload;
  const { items } = shoppingCart;
  const newItems = parseProductsData(
    items,
    flattenedMenu,
    flattenedCombos,
    undefined,
    basePizzaData,
    state,
    sizesAndCrusts
  );
  const newShoppingCart = {
    ...shoppingCart,
    items: newItems
  };

  LocalStorageService.setShoppingCart(newShoppingCart, '', expire3am);
  return newShoppingCart;
});

export const onGetOPTCartPricesSuccess = onSuccess((action, state) => {
  const { flattenedMenu, flattenedCombos } = state;
  const shoppingCart = action.payload[OPTIMIZED_SHOPPING_CART_TARGET] || action.payload[SHOPPING_CART_TARGET];
  const { basePizzaData, sizesAndCrusts } = action.payload;
  const { items } = shoppingCart;
  const newItems = parseProductsData(
    items,
    flattenedMenu,
    flattenedCombos,
    undefined,
    basePizzaData,
    state,
    sizesAndCrusts
  );
  const newShoppingCart = {
    ...shoppingCart,
    items: newItems
  };
  if (action.payload[OPTIMIZED_SHOPPING_CART_TARGET]) {
    LocalStorageService.setOptimizedShoppingCart(newShoppingCart);
  } else {
    LocalStorageService.setShoppingCart(newShoppingCart, '', expire3am);
  }

  return newShoppingCart;
});

export const onGetMenuHelperSuccess = onSuccess(action => {
  const menuHelpers = action.payload.reduce(
    (acc, item) => ({
      ...acc,
      [item.menuable.category]: item
    }),
    {}
  );

  return menuHelpers;
});

export const onClearCart = onSuccess(() => {
  LocalStorageService.removeShoppingCart();

  return { items: [] };
});

export const onGetBannerImages = (state, action) => {
  const mapBanner = banner => {
    const bannerableId =
      banner.bannerableType === productTypes.COMPLEMENT_VARIANT ||
      banner.bannerableType === productTypes.PIZZA_VARIANT
        ? banner.bannerable.productId
        : banner.bannerableId;
    let { bannerableType } = banner;

    if (bannerableType === productTypes.PIZZA_VARIANT) {
      bannerableType = productTypes.PIZZA;
    } else if (banner.bannerableType === productTypes.COMPLEMENT_VARIANT) {
      bannerableType = productTypes.COMPLEMENT;
    }

    return {
      id: banner.id,
      name: banner.name,
      pictureUrl: getPhotoThumbnail(banner, PICTURE_SIZES.XL),
      videoUrl: getVideoUrl(banner.videos),
      duration: banner.duration * SECONDS_IN_MILLISECONDS,
      ...(banner.product && { product: banner.product }),
      ...(banner.url && { url: banner.url }),
      bannerableId,
      bannerableType
    };
  };
  const web = action.payload.filter(banner => banner.category === BANNER_IMAGE_TYPES.web).map(mapBanner);
  const responsive = action.payload
    .filter(banner => banner.category === BANNER_IMAGE_TYPES.responsive)
    .map(mapBanner);

  return state.merge({
    [BANNER_IMAGES_TARGET]: { web, responsive },
    [`${BANNER_IMAGES_TARGET}Loading`]: false
  });
};

export const onClearOptimizedItems = state => {
  const newCart = {
    ...state[SHOPPING_CART_TARGET],
    optimizedItems: []
  };
  LocalStorageService.setShoppingCart(newCart);

  return state.merge({
    [SHOPPING_CART_TARGET]: newCart
  });
};

export const removeAlcoholProduct = onSuccess((action, state) => {
  const {
    shoppingCart: { items },
    menu
  } = state;

  const itemData = items.filter(item => {
    if (item.category !== PRODUCT_CATEGORIES.DRINK) {
      return item;
    }

    const isAlcohol = menu.some(({ menuable }) => menuable.isAlcohol && item.menuableId === menuable.id);

    if (!isAlcohol) {
      return item;
    }
    return false;
  });

  return {
    ...state[SHOPPING_CART_TARGET],
    items: [...itemData]
  };
});

export const onUpdateUpsellingVariants = onReadValue(action => {
  LocalStorageService.setUpsellingVariants(action.payload);
  return action.payload;
});
