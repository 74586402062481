/* eslint-disable camelcase */
/* eslint-disable max-statements */
/* eslint-disable complexity */
/* eslint-disable max-lines */
import {
  completeTypes,
  createTypes,
  withSuccess,
  withFailure,
  withPostSuccess,
  withPostFailure,
  withPreFetch
} from 'redux-recompose';
import { t } from 'i18next';
import { push } from 'connected-react-router';

import {
  ORDER_CONFIRMATION_MODAL,
  ADD_CARD,
  DELETE_CREDIT_CARD_MODAL,
  CONFIRMED_ORDER_MODAL,
  DISABLED_ITEMS_MODAL,
  CHANGE_ADDRESS,
  QUICK_ORDER_MODAL
} from '~redux/Modal/constants';
import {
  DISPATCH_METHOD_TARGET,
  CURRENT_SUBSIDIARY_TARGET,
  ACTUAL_RECEIPT_COMPANY
} from '~redux/SearchStore/constants';
import { actionCreators as notificationActions } from '~redux/Notification/actions';
import { actionCreators as customPizzaActions } from '~redux/CustomPizza/actions';
import { actionCreators as modalActions } from '~redux/Modal/actions';
import { actionCreators as authActions } from '~redux/Auth/actions';
import { actionCreators as papaPointsActions } from '~redux/PapaPoints/actions';
import { actionCreators as promoSellingActions } from '~redux/PromoSelling/actions';
import { actionCreators as orderActions } from '~redux/Orders/actions';
import PriceService from '~services/Price/service';
import ShoppingCartService from '~services/ShoppingCart/service';
import OrderService from '~services/Order/service';
import AuthService from '~services/Auth/service';
import { deserializer } from '~services/baseSerializers';
import { NOTIFICATION_DANGER, NOTIFICATION_INFO } from '~redux/Notification/constants';
import { CARD_PAYMENT_METHODS_ONLINE, PAYMENT_METHODS } from '~constants/paymentMethods';
import PictureService from '~services/Picture/service';
import { flattenMenu, flattenCombos } from '~utils/menu';
import { cartHasRuleApplied, purgeCartItems } from '~utils/shoppingCart';
import { CARD_PAYMENT_TYPE, COUNTRIES, CURRENT_COUNTRY_ID } from '~constants/environment';
import { CLIENT_PAGES } from '~constants/pages';
import { buildBodyWithRulesInItems } from '~services/ShoppingCart/utils';
import { CURRENT_USER_TARGET } from '~redux/Auth/constants';
import { getRoute } from '~screens/CallCenter/utils';
import { DYNAMIC_PRICES_ENABLED } from '~constants/features';
import { validateTimeRange } from '~utils/time';
import { setSession } from '~utils/sessionStorage';
import { updateUpsellingVariants } from '~models/upselling';
import upsellingActions from '~redux/Upselling/actions';
import { arrayHasLength } from '~utils/array';
import { enablePromoSellingByCountry } from '~components/CartContent/components/PromoSelling/utils';
import { ORDER_STATUSES } from '~redux/Orders/constants';
import { validateDispatchMethod } from '~models/shoppingCart';
import { isGiftCardCode } from '~models/giftCard';
import { useGiftCardGTMEvent } from '~gtmEvents/giftCards';
import { mercantilEnabled } from '~utils/paymentMethods';
import { getIpAddress } from '~utils/ip';

import {
  denormalizeHelpersAndMenu,
  checkErrorsInOrderBody,
  couponHasError,
  addWithLoyaltyToCart,
  orderErrorHandling,
  persistProps,
  getRedeemCodeType,
  getRedeemedCodeInformation
} from './utils';
import {
  MENU_TARGET,
  SHOPPING_CART_TARGET,
  OPTIMIZED_SHOPPING_CART_TARGET,
  FIX_NAV_TARGET,
  CURRENT_ORDER_TARGET,
  COMBOS_TARGET,
  SUGGESTIVE_SELLS_TARGET,
  OFFER_COMPLETIONS_TARGET,
  REDEEMED_CODE_TARGET,
  INVALID_CODE_DETAILS_ERRORS,
  BANNER_IMAGES_TARGET,
  BANNER_IMAGE_TYPES,
  CAN_ACCESS_CHECKOUT_TARGET,
  FLATTENED_MENU_TARGET,
  FLATTENED_COMBOS_TARGET,
  EXTERNAL_OFFER_TARGET,
  GIFTCARD_OVER_SUBTOTAL_TARGET,
  COUPON_KIND,
  TIP_TARGET,
  INITIAL_TIP,
  PAYMENT_CARDS_TARGET,
  ADD_PAYMENT_CARD_TARGET,
  DELETE_PAYMENT_CARD_TARGET,
  ORDER_SETTINGS_TARGET,
  CORE_SETTINGS_TARGET,
  PAYMENT_CARDS_ERRORS,
  ONECLICK_PARAMS,
  ONECLICK_ADD_CARD_PARAM,
  ORDER_RULE,
  ERROR_RULE_NOT_COMPLETED,
  ORDER_DATA_CONSUMING_TARGET,
  AGE_VERIFICATION,
  PROMOTED_PRODUCTS,
  COUPON_DISPATCH_METHOD,
  FILTERED_MENU,
  CATALAN_LANGUAGE_ACTIVATED,
  GUEST_PAYMENT_CARD_TARGET,
  IS_GUEST_CARD_TARGET,
  DISABLED_ITEMS,
  INVALID_CATEGORY_COUPON
} from './constants';
import { paymentsActions } from './paymentsActions';

const completedTypes = completeTypes(
  [
    'GET_MENU',
    'GET_MENU_HELPERS',
    'GET_CART_PRICES',
    'CREATE_ORDER',
    'POLL_CREATE_ORDER',
    'SET_PIZZA_BASES',
    'GET_SUGGESTIVE_SELLS',
    'GET_SUGGESTIVE_PROMOS',
    'GET_OFFER_COMPLETIONS',
    'GET_OPTIMIZED_CART',
    'GET_OFFER',
    'GET_BANNER_IMAGES',
    'CHECK_CART_AVAILABLE',
    'CHECK_EXTERNAL_OFFER',
    'ADD_PAYMENT_CARD',
    'GET_PAYMENT_CARDS',
    'DELETE_PAYMENT_CARD',
    'GET_ORDER_SETTINGS',
    'GET_CORE_SETTINGS',
    'START_ONECLICK_CARD_INSCRIPTION',
    'GET_ORDER_RULES',
    'SUBMIT_ELECTRONIC_RECEIPT',
    'CLEAR_OPTIMIZED_ITEMS',
    'SET_ELECTRONIC_FORM_VALID',
    'SET_AGE_VERIFICATION',
    'GET_PROMOTED_PRODUCTS',
    'SEND_ABANDONED_CART'
  ],
  [
    'SET_TIP',
    'RESET_TIP',
    'FLATTEN_MENU',
    'FLATTEN_COMBOS',
    'SET_SHOULD_FIX_NAV',
    'SET_CHECKOUT_ACCESS',
    'CLEAR_CART',
    'CLEAR_ORDER_DATA',
    'CLEAR_SUGGESTIVE_PROMOS',
    'SET_CUSTOM_PIZZA_SELECTIONS',
    'SET_CUSTOM_PIZZA_CONFIG',
    'SET_COMBOS',
    'REDEEM_CODE',
    'GIFTCARD_OVER_SUBTOTAL',
    'SET_EXTERNAL_OFFER',
    'CLEAR_PAYMENT_CARDS',
    'CLEAN_ORDER_DATA_CONSUMING',
    'SET_VALUE',
    'SET_FORCE_TIP',
    'REMOVE_ALCOHOL_CART',
    'SET_UPSELLING_VARIANTS',
    'SET_FILTERED_MENU',
    'CATALAN_LANGUAGE_ACTIVATED',
    'SET_IS_GUEST_CARD',
    'SET_GUEST_PAYMENT_CARD'
  ]
);

export const actions = createTypes(completedTypes, '@@MENU');

export const privateActionCreators = {
  createOrderFailure: payload => ({
    type: actions.CREATE_ORDER_FAILURE,
    target: CURRENT_ORDER_TARGET,
    payload
  }),
  createOrderSuccess: payload => ({
    type: actions.CREATE_ORDER_SUCCESS,
    target: CURRENT_ORDER_TARGET,
    payload
  })
};

export const actionCreators = {
  setTip: tip => ({
    type: actions.SET_TIP,
    target: TIP_TARGET,
    payload: tip
  }),
  resetTip: () => ({
    type: actions.RESET_TIP,
    target: TIP_TARGET,
    payload: INITIAL_TIP
  }),
  setAlertAmountIsOverSubtotal: data => ({
    type: actions.GIFTCARD_OVER_SUBTOTAL,
    target: GIFTCARD_OVER_SUBTOTAL_TARGET,
    payload: data
  }),
  setExternalOffer: offerId => ({
    type: actions.SET_EXTERNAL_OFFER,
    target: EXTERNAL_OFFER_TARGET,
    payload: offerId
  }),
  canAccessCheckout: (canAccess = false) => ({
    type: actions.SET_CHECKOUT_ACCESS,
    target: CAN_ACCESS_CHECKOUT_TARGET,
    payload: canAccess
  }),
  getMenu: (storeId, dispatchMethod, onSuccess) => (dispatch, getState) => {
    const state = getState();
    const externalOffer = state.home[EXTERNAL_OFFER_TARGET];

    dispatch({
      type: actions.GET_MENU,
      target: MENU_TARGET,
      service: PriceService.getPrices,
      payload: { storeId, dispatchMethod, offerId: externalOffer },
      successSelector: response => {
        const { menu } = denormalizeHelpersAndMenu(response);

        return menu;
      },
      injections: [
        withPostSuccess((_, response) => {
          const { helpers } = denormalizeHelpersAndMenu(response);
          const { menu, combos } = deserializer.serialize(response);

          dispatch(customPizzaActions.setHelpers(helpers));

          dispatch({
            type: actions.SET_COMBOS,
            target: COMBOS_TARGET,
            payload: combos.data
          });

          dispatch({
            type: actions.FLATTEN_MENU,
            target: FLATTENED_MENU_TARGET,
            payload: flattenMenu(menu.data)
          });

          dispatch({
            type: actions.FLATTEN_COMBOS,
            target: FLATTENED_COMBOS_TARGET,
            payload: flattenCombos(combos.data)
          });

          if (onSuccess) {
            onSuccess();
          }
        })
      ]
    });
  },
  setFilteredMenu: filteredMenu => ({
    type: actions.SET_FILTERED_MENU,
    target: FILTERED_MENU,
    payload: filteredMenu
  }),
  catalanLanguageActivated: activated => ({
    type: actions.CATALAN_LANGUAGE_ACTIVATED,
    target: CATALAN_LANGUAGE_ACTIVATED,
    payload: activated
  }),
  getCartPrices:
    (items, options = {}) =>
    async (dispatch, getState) => {
      const state = getState();
      const {
        onSuccess,
        storeId: storeIdSelected,
        dispatchMethod: dispatchMethodSelected,
        disabledSuggestivePromos = false
      } = options;
      const { currentSubsidiary: store, dispatchType: dispatchMethod } = state.searchStore;
      const { currentUser } = state.auth;
      const { canAccessCheckout } = state.home;
      const cart = state.home[SHOPPING_CART_TARGET];
      const externalOffer = state.home[EXTERNAL_OFFER_TARGET];
      const redeemedCode = state.home[REDEEMED_CODE_TARGET];
      const pathname = state.router.location?.pathname;
      const codeToRedeem = (!redeemedCode?.errorMessage && redeemedCode?.code) || options?.redeemCode;
      const sizesAndCrusts = state.sizesAndCrusts?.data.translations;
      await dispatch({
        type: actions.GET_CART_PRICES,
        target: SHOPPING_CART_TARGET,
        service: ShoppingCartService.getPrices,
        payload: {
          storeId: storeIdSelected || store.id,
          dispatchMethod: validateDispatchMethod(items, dispatchMethodSelected || dispatchMethod),
          items: items || cart?.items,
          externalUserId: currentUser?.externalId,
          ...(externalOffer && { offerId: externalOffer }),
          ...getRedeemCodeType(codeToRedeem)
        },
        successSelector: response => {
          const shoppingCart = addWithLoyaltyToCart(deserializer.serialize(response.data));
          shoppingCart.items = persistProps(shoppingCart.items, items);
          return {
            shoppingCart,
            basePizzaData: state.customPizza.config.basePizzaData,
            sizesAndCrusts
          };
        },
        injections: [
          // eslint-disable-next-line complexity
          withPostSuccess((_, response, currentState) => {
            const data = response?.data && deserializer.serialize(response?.data);
            const cartHasItems = arrayHasLength(data?.items);
            const appliedPromos = data?.appliedPromos;
            const giftCard = data?.giftCard;
            const discountIsGiftcard = appliedPromos[0]?.appliedCoupon?.kind === COUPON_KIND.GIFTCARD;
            const discountIsOverSubtotal =
              discountIsGiftcard &&
              appliedPromos[0]?.appliedCoupon?.discount !== appliedPromos[0]?.appliedCoupon?.value;
            const cartItems = currentState.home[SHOPPING_CART_TARGET]?.items;
            if (data) {
              if (currentUser) {
                const spentPoints = deserializer.serialize(response.data).pointsSpent;
                const currentPoints = currentUser.totalPoints - spentPoints;

                dispatch(papaPointsActions.setCurrentPoints(currentPoints));
              }
            }
            if (appliedPromos?.length || giftCard) {
              const code = options?.redeemCode || redeemedCode?.code;
              dispatch(
                actionCreators.redeemCode(
                  getRedeemedCodeInformation({
                    redeemedCode: code,
                    useGiftCard: isGiftCardCode(code) && (!redeemedCode?.code || redeemedCode?.useGiftCard),
                    amount: giftCard?.amount,
                    isCampaign: appliedPromos[0]?.appliedCoupon?.campaign
                  })
                )
              );
              if (discountIsOverSubtotal) {
                dispatch(
                  actionCreators.setAlertAmountIsOverSubtotal({
                    data: true
                  })
                );
                dispatch(
                  notificationActions.show({
                    message: t('Home:discountIsOverSubtotal'),
                    type: NOTIFICATION_INFO
                  })
                );
              } else {
                dispatch(
                  actionCreators.setAlertAmountIsOverSubtotal({
                    data: false
                  })
                );
              }
            }

            if (canAccessCheckout && !cartHasItems) {
              dispatch(actionCreators.canAccessCheckout(false));
            }
            if (!canAccessCheckout && cartHasItems) {
              dispatch(actionCreators.canAccessCheckout(true));
            }
            if (onSuccess) {
              onSuccess();
            }

            dispatch(
              upsellingActions.updateVariants(variants =>
                updateUpsellingVariants({
                  upsellingVariants: variants,
                  items: cartItems,
                  location: pathname,
                  sizesAndCrusts
                })
              )
            );
            if (enablePromoSellingByCountry() && !disabledSuggestivePromos) {
              dispatch(promoSellingActions.getSuggestivePromos(store.id, cartItems, dispatchMethod));
            }
          }),
          withPostFailure((_, response) => {
            const { metadata, code, description: codeDescription } = response?.data;
            const categories = metadata?.category?.map(category => ` ${t(`Menu:${category}`)}`);
            const codeV2 = codeDescription === INVALID_CATEGORY_COUPON && !metadata?.variant;
            // eslint-disable-next-line camelcase
            const extraMetadata = metadata && { current_store: store.name, ...metadata, categories };

            const description = codeDescription ? (codeV2 ? `${codeDescription}_V2` : codeDescription) : null;

            if (couponHasError(description)) {
              const redeemCodeErrorMessage =
                INVALID_CODE_DETAILS_ERRORS[description] || INVALID_CODE_DETAILS_ERRORS.INVALID_COUPON_CODE;
              dispatch(
                actionCreators.redeemCode({
                  code: redeemedCode?.code || options?.redeemCode,
                  errorMessage: redeemCodeErrorMessage,
                  extraMetadata,
                  loading: false,
                  ok: false
                })
              );
              if (description === COUPON_DISPATCH_METHOD) {
                dispatch(actionCreators.getCartPrices(items));
              }
            } else {
              if (description === ERROR_RULE_NOT_COMPLETED) {
                dispatch(actionCreators.clearCart());
                dispatch(actionCreators.getCartPrices(purgeCartItems(items)));
                dispatch(actionCreators.setValue(ORDER_RULE, null));
              }
              dispatch(
                notificationActions.show({
                  message: t(`APIErrors:e${code}${codeV2 ? 'v2' : ''}`, {
                    ...(extraMetadata && extraMetadata),
                    categories
                  }),
                  type: description === ERROR_RULE_NOT_COMPLETED ? NOTIFICATION_INFO : NOTIFICATION_DANGER
                })
              );
              if (description === DISABLED_ITEMS) {
                dispatch(modalActions.closeModal(CHANGE_ADDRESS));
                dispatch(modalActions.openModal(DISABLED_ITEMS_MODAL));
              }
            }
          })
        ]
      });
    },
  clearCart: () => dispatch => {
    dispatch({
      type: actions.CLEAR_CART,
      target: SHOPPING_CART_TARGET
    });
    dispatch(upsellingActions.clearVariants());
    dispatch(actionCreators.clearRedeemCode());
  },
  checkCartItemsAvailability: () => (dispatch, getState) => {
    const state = getState();
    const { searchStore, home, auth } = state;
    const { currentSubsidiary, dispatchType } = searchStore;
    const { shoppingCart } = home;
    const { currentUser } = auth;
    const externalOffer = state.home[EXTERNAL_OFFER_TARGET];

    dispatch({
      type: actions.CHECK_CART_AVAILABLE,
      target: CAN_ACCESS_CHECKOUT_TARGET,
      service: ShoppingCartService.getPrices,
      payload: {
        storeId: currentSubsidiary.id,
        dispatchMethod: dispatchType,
        items: shoppingCart.items,
        externalUserId: currentUser?.externalId,
        ...(externalOffer && { offerId: externalOffer })
      },
      injections: [
        withPostFailure(() => {
          dispatch(actionCreators.clearCart());
        })
      ]
    });
  },
  setShouldFixNav: fix => ({
    type: actions.SET_SHOULD_FIX_NAV,
    payload: fix,
    target: FIX_NAV_TARGET
  }),
  clearOrderData: () => ({
    type: actions.CLEAR_ORDER_DATA,
    target: CURRENT_ORDER_TARGET
  }),
  createOrder: order => (dispatch, getState) => {
    // TODO: Check why it's required to call the action twice for it to dispatch properly
    // dispatch({ type: actions.CREATE_ORDER, target: CURRENT_ORDER_TARGET });
    dispatch({
      type: actions.CREATE_ORDER,
      target: CURRENT_ORDER_TARGET,
      service: OrderService.createOrder,
      payload: buildBodyWithRulesInItems(order),
      injections: [
        withPreFetch(() => {
          const state = getState();
          const orderErrors = checkErrorsInOrderBody(order);
          const currentUser = state.auth?.currentUser;
          const { user } = order;
          if (currentUser && (!currentUser.phone || !currentUser.idNumber)) {
            dispatch(
              authActions.updateUser({
                ...(!currentUser.phone && { phone: user.phone }),
                ...(!currentUser.idNumber && { idNumber: user.idNumber })
              })
            );
          }
          if (orderErrors) {
            dispatch(privateActionCreators.createOrderFailure({ error: orderErrors }));
          }
          dispatch(orderActions.checkoutButtonToggleLoading(true));
          dispatch(orderActions.currentOrderToggleLoading(true));
          dispatch(modalActions.openModal(ORDER_CONFIRMATION_MODAL));
        }),
        withSuccess(async (_, response) => {
          dispatch(modalActions.closeModal(QUICK_ORDER_MODAL));
          const data = response.data && deserializer.serialize(response.data);
          const priceToPay = data?.totalPrice + data?.tip;
          if (data?.status === ORDER_STATUSES.CREATED && priceToPay === 0) {
            useGiftCardGTMEvent(data);
            dispatch(orderActions.getActiveOrders());
            window.setTimeout(() => dispatch(push(CLIENT_PAGES.PURCHASE_OK.path)), 500);
            return;
          }
          const paymentActions =
            response.data &&
            priceToPay > 0 &&
            (CURRENT_COUNTRY_ID === COUNTRIES.cl ? priceToPay > 1 : true) &&
            paymentsActions({ response, order }, privateActionCreators);
          const paymentAction = paymentActions?.[order.paymentMethod];
          const isDirectClickToPay = [
            PAYMENT_METHODS.directClickToPay,
            PAYMENT_METHODS.sodexo,
            PAYMENT_METHODS.edenred
          ].includes(order.paymentMethod);
          let deviceData = null;
          if (isDirectClickToPay) {
            const ipAddress = await getIpAddress();
            deviceData = {
              device: {
                acceptHeader: 'application/json',
                browserData: {
                  colorDepth: screen.colorDepth,
                  javaEnabled: true,
                  javaScriptEnabled: true,
                  screenHeight: screen.height.toString(),
                  screenWidth: screen.width.toString()
                },
                ipAddress,
                locale: navigator.language.replace('-', '_'),
                timezoneOffsetUtcMinutes: (-new Date().getTimezoneOffset()).toString(),
                userAgent: navigator.userAgent
              }
            };
          }
          window.setTimeout(
            () =>
              dispatch(
                paymentAction
                  ? paymentAction(isDirectClickToPay ? deviceData : undefined)
                  : actionCreators.createCashOrder(response, order)
              ),
            500
          );
        }),
        withFailure((_, response) => {
          const responseError = response.data?.errors || response.data;
          const { code, metadata } = responseError;
          const errorMessage = orderErrorHandling(responseError, order);

          dispatch(orderActions.checkoutButtonToggleLoading(false));
          dispatch(privateActionCreators.createOrderFailure({ message: errorMessage, code, metadata }));
          dispatch(orderActions.currentOrderToggleLoading(false));
          dispatch(
            notificationActions.show({
              message: errorMessage,
              type: NOTIFICATION_DANGER
            })
          );
        })
      ]
    });
  },
  createCashOrder: (responseOrder, order) => dispatch => {
    dispatch(modalActions.closeModal(QUICK_ORDER_MODAL));
    if (order.agent) {
      dispatch(modalActions.closeModal(ORDER_CONFIRMATION_MODAL));
      dispatch(modalActions.openModal(CONFIRMED_ORDER_MODAL));
    } else {
      dispatch(push(CLIENT_PAGES.PURCHASE_OK.path));
    }

    const data = responseOrder?.data && deserializer.serialize(responseOrder?.data);
    dispatch(privateActionCreators.createOrderSuccess(data));
    dispatch(orderActions.getActiveOrders());
  },
  createGuestOrder: order => async (dispatch, getState) => {
    const orderData = { ...order };
    const { user, address } = order;
    await dispatch(authActions.createGuestUser({ user, address }));
    const state = getState();
    const { company } = state.searchStore[ACTUAL_RECEIPT_COMPANY];
    const { externalId } = state.auth.callCenterGuestUser || {};
    if (company) {
      const resultCompany = await AuthService.saveCompany({ company, externalId });
      orderData.companyId = resultCompany.data.id;
    }
    dispatch(actionCreators.createOrder(orderData));
  },
  cleanOrderDataForConsuming: () => ({
    type: actions.CLEAN_ORDER_DATA_CONSUMING,
    target: ORDER_DATA_CONSUMING_TARGET,
    payload: null
  }),
  getSuggestiveSells: (storeId, shoppingCart, dispatchMethod) => ({
    type: actions.GET_SUGGESTIVE_SELLS,
    target: SUGGESTIVE_SELLS_TARGET,
    service: ShoppingCartService.getSuggestiveSells,
    payload: { storeId, shoppingCart, dispatchMethod },
    successSelector: response => deserializer.serialize(response.data)
  }),
  getOfferCompletions: () => (dispatch, getState) => {
    const state = getState();
    const storeId = state.searchStore[CURRENT_SUBSIDIARY_TARGET]?.id;
    const dispatchMethod = state.searchStore[DISPATCH_METHOD_TARGET];
    const { currentUser } = state.auth[CURRENT_USER_TARGET] || {};
    const shoppingCart = state.home[SHOPPING_CART_TARGET];
    dispatch({
      type: actions.GET_OFFER_COMPLETIONS,
      target: OFFER_COMPLETIONS_TARGET,
      service: ShoppingCartService.getOfferCompletions,
      payload: { storeId, shoppingCart, dispatchMethod, currentUser },
      successSelector: response => deserializer.serialize(response.data)
    });
  },
  getOptimizedCart:
    (acceptPromos = false) =>
    (dispatch, getState) => {
      const state = getState();
      const storeId = state.searchStore[CURRENT_SUBSIDIARY_TARGET]?.id;
      const dispatchMethod = state.searchStore[DISPATCH_METHOD_TARGET];
      const { currentUser } = state.auth[CURRENT_USER_TARGET] || {};
      const shoppingCart = state.home[SHOPPING_CART_TARGET];
      const sizesAndCrusts = state.sizesAndCrusts?.data.translations;
      dispatch({
        type: acceptPromos ? actions.GET_CART_PRICES : actions.GET_OPTIMIZED_CART,
        target: acceptPromos ? SHOPPING_CART_TARGET : OPTIMIZED_SHOPPING_CART_TARGET,
        service: ShoppingCartService.getOptimizedCart,
        payload: { storeId, shoppingCart, dispatchMethod, currentUser },
        successSelector: response => {
          const data = deserializer.serialize(response.data);
          if (acceptPromos) {
            return { shoppingCart: data };
          }
          return { optimizedShoppingCart: data, sizesAndCrusts };
        }
      });
    },
  getOrderRules: (storeId, shoppingCart, dispatchMethod) => (dispatch, getState) => {
    const state = getState();
    const currentUser = state.auth[CURRENT_USER_TARGET];
    dispatch({
      type: actions.GET_ORDER_RULES,
      target: ORDER_RULE,
      service: ShoppingCartService.getOrderRules,
      payload: { storeId, shoppingCart, dispatchMethod, externalUserId: currentUser?.externalId },
      successSelector: response => response.data && deserializer.serialize(response.data)
    });
  },
  applyCoupon:
    ({ items, redeemCode }) =>
    dispatch => {
      dispatch(actionCreators.redeemCode({ loading: true, ok: true }));
      dispatch(actionCreators.getCartPrices(items, { redeemCode }));
    },
  redeemCode: data => ({
    type: actions.REDEEM_CODE,
    target: REDEEMED_CODE_TARGET,
    payload: data
  }),
  clearRedeemCode: () => dispatch => {
    dispatch(actionCreators.redeemCode({ code: '', loading: true, ok: false }));
  },
  getBannerImages: offerId => (dispatch, getState) => {
    const state = getState();
    const { searchStore } = state;
    const dispatchMethod = searchStore[DISPATCH_METHOD_TARGET];
    const storeId = searchStore[CURRENT_SUBSIDIARY_TARGET]?.id;

    dispatch({
      type: actions.GET_BANNER_IMAGES,
      target: BANNER_IMAGES_TARGET,
      service: PictureService.dealPictures,
      payload: {
        dispatchMethod,
        category: BANNER_IMAGE_TYPES.web,
        offerId,
        storeId
      },
      successSelector: response => deserializer.serialize(response.data)
    });
  },
  addPaymentCard: (card, isMercantil) => ({
    type: actions.ADD_PAYMENT_CARD,
    service: AuthService.addPaymentCard,
    payload: { card, isMercantil },
    target: ADD_PAYMENT_CARD_TARGET,
    successSelector: response => deserializer.serialize(response.data),
    injections: [
      withPostSuccess(dispatch => {
        dispatch(modalActions.closeModal(ADD_CARD));
        dispatch(actionCreators.getPaymentCards(isMercantil));
      }),
      withPostFailure((dispatch, response) => {
        const error = response?.data?.fields?.[0].errors?.[0];
        const errorMessage = error ? PAYMENT_CARDS_ERRORS[error] : `e${response?.data?.code}`;

        dispatch(
          notificationActions.show({
            message: t(`APIErrors:${errorMessage}`),
            type: NOTIFICATION_DANGER
          })
        );
      })
    ]
  }),
  getPaymentCards: isMercantil => ({
    type: actions.GET_PAYMENT_CARDS,
    target: PAYMENT_CARDS_TARGET,
    payload: isMercantil,
    service: AuthService.getPaymentCards,
    successSelector: response => deserializer.serialize(response.data)
  }),
  deleteCreditCard: id => dispatch => {
    let service = null;
    if (CARD_PAYMENT_TYPE === CARD_PAYMENT_METHODS_ONLINE.powertranz) {
      service = AuthService.deletePowertranzPaymentCard;
    } else if (CARD_PAYMENT_TYPE === CARD_PAYMENT_METHODS_ONLINE.oneclick) {
      service = AuthService.deleteOneclickPaymentCard;
    } else if (CARD_PAYMENT_TYPE === CARD_PAYMENT_METHODS_ONLINE.directClickToPay) {
      service = AuthService.deleteIngenicoPaymentCard;
    } else if (CARD_PAYMENT_TYPE === CARD_PAYMENT_METHODS_ONLINE.mercantil) {
      service = AuthService.deleteMercantilPaymentCard;
    }

    return dispatch({
      type: actions.DELETE_PAYMENT_CARD,
      target: DELETE_PAYMENT_CARD_TARGET,
      service,
      payload: id,
      injections: [
        withPostSuccess((_, res, currentState) => {
          dispatch(modalActions.closeModalWithData(DELETE_CREDIT_CARD_MODAL));
          dispatch(
            notificationActions.show({
              message: t('CheckoutPaymentMethodList:deletedCardSuccessfully'),
              type: NOTIFICATION_INFO
            })
          );
          dispatch(
            actionCreators.getPaymentCards(mercantilEnabled(currentState.searchStore?.currentSubsidiary))
          );
        }),
        withPostFailure(() => {
          dispatch(
            notificationActions.show({
              message: t('CheckoutPaymentMethodList:deletedCardError'),
              type: NOTIFICATION_DANGER
            })
          );
        })
      ]
    });
  },
  clearPaymentCards: () => ({
    type: actions.CLEAR_PAYMENT_CARDS,
    target: PAYMENT_CARDS_TARGET,
    payload: []
  }),
  getOrderSettings: () => ({
    type: actions.GET_ORDER_SETTINGS,
    target: ORDER_SETTINGS_TARGET,
    service: OrderService.getOrderSettings,
    successSelector: response => deserializer.serialize(response.data)
  }),
  getCoreSettings: () => ({
    type: actions.GET_CORE_SETTINGS,
    target: CORE_SETTINGS_TARGET,
    service: OrderService.getCoreSettings,
    successSelector: response => {
      const dataDeserializer = deserializer.serialize(response.data);
      const { openingAlcoholTime, closingAlcoholTime } = dataDeserializer;
      return {
        ...dataDeserializer,
        enableAlcoholTime: validateTimeRange(openingAlcoholTime, closingAlcoholTime)
      };
    }
  }),
  startOneclickCardInscription: data => ({
    type: actions.START_ONECLICK_CARD_INSCRIPTION,
    target: ONECLICK_PARAMS,
    service: AuthService.addPaymentCard,
    payload: data,
    injections: [
      withPreFetch(dispatch => {
        dispatch(
          notificationActions.show({
            message: t('CheckoutPaymentMethodList:addCardRedirectionMessage'),
            type: NOTIFICATION_INFO
          })
        );
        dispatch({ type: actions.START_ONECLICK_CARD_INSCRIPTION, target: ONECLICK_PARAMS });
      }),
      withSuccess((dispatch, response) => {
        const { token, urlWebpay } = deserializer.serialize(response.data);
        window.location.href = `${urlWebpay}?${ONECLICK_ADD_CARD_PARAM}=${token}`;
      }),
      withPostFailure((dispatch, response) =>
        dispatch(
          notificationActions.show({
            message: t(`APIErrors:e${response?.data?.code}`),
            type: NOTIFICATION_DANGER
          })
        )
      )
    ]
  }),
  goToCheckoutWithSuggestions: () => async (dispatch, getState) => {
    const state = getState();
    const cart = state.home[SHOPPING_CART_TARGET];
    const callCenterUser = state.auth?.callCenterGuestUser;
    if (DYNAMIC_PRICES_ENABLED) {
      if (!cartHasRuleApplied(cart)) {
        await dispatch(actionCreators.getOptimizedCart());
      }
      dispatch(actionCreators.getOfferCompletions());
    }
    dispatch(push(getRoute(callCenterUser).checkout));
  },
  clearOptimizedItems: () => ({
    type: actions.CLEAR_OPTIMIZED_ITEMS,
    target: SHOPPING_CART_TARGET
  }),
  setValue: (target, value) => ({
    type: actions.SET_VALUE,
    target,
    payload: value
  }),
  setAgeVerification: value => {
    setSession(AGE_VERIFICATION, value);
    return {
      type: actions.SET_AGE_VERIFICATION,
      target: AGE_VERIFICATION,
      payload: value
    };
  },
  removeAlcoholCart: () => ({
    type: actions.REMOVE_ALCOHOL_CART,
    target: SHOPPING_CART_TARGET
  }),
  getPromotedProducts: (storeId, shoppingCart, dispatchMethod) => ({
    type: actions.GET_PROMOTED_PRODUCTS,
    target: PROMOTED_PRODUCTS,
    service: ShoppingCartService.getPromotedProducts,
    payload: { storeId, shoppingCart, dispatchMethod },
    successSelector: response => deserializer.serialize(response.data)
  }),
  setIsGuestCard: value => ({
    type: actions.SET_IS_GUEST_CARD,
    payload: value,
    target: IS_GUEST_CARD_TARGET
  }),
  setGuestPaymentCard: cardInfo => ({
    type: actions.SET_GUEST_PAYMENT_CARD,
    target: GUEST_PAYMENT_CARD_TARGET,
    payload: cardInfo
  }),
  selligentAbandonedCart: () => ({
    type: actions.SEND_ABANDONED_CART,
    service: AuthService.selligentAbandonedCart,
    payload: {
      user_abandoned_cart: {
        date_timeout: new Date().toISOString().slice(0, 19),
        view_name: 'page7'
      }
    }
  })
};
