import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';

import api, { TOKEN_TYPE } from '~config/api';
import LocalStorageService from '~services/LocalStorageService';

const poolData = {
  UserPoolId: process.env.REACT_APP_USER_POOL_ID,
  ClientId: process.env.REACT_APP_CLIENT_ID
};

export const cognitoUserPool = new CognitoUserPool(poolData);

export const getSessionTokens = session => ({
  idToken: session.getIdToken().getJwtToken(),
  accessToken: session.getAccessToken().getJwtToken(),
  refreshToken: session.getRefreshToken()?.getToken() || session.getRefreshToken()
});

export const getCognitoUser = username => new CognitoUser({ Username: username, Pool: cognitoUserPool });

export const checkSession = request =>
  new Promise(resolve => {
    const cognitoUser = cognitoUserPool.getCurrentUser();

    if (cognitoUser) {
      cognitoUser.getSession((error, session) => {
        if (!error) {
          const { idToken } = getSessionTokens(session);
          const newAuthorizationHeader = `${TOKEN_TYPE} ${idToken}`;

          if (request.headers.Authorization !== newAuthorizationHeader) {
            request.headers.Authorization = newAuthorizationHeader;
            LocalStorageService.setAuthToken(newAuthorizationHeader);
          }
        }
        resolve();
      });
    } else {
      resolve();
    }
  });

api.addAsyncRequestTransform(checkSession);
