function getMobileOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
}

export function isSafariBrowser() {
  return /^((?!chrome|android|crios|fxios|edge\/|edg\/|fb|instagram).)*safari/i.test(navigator.userAgent);
}

export const isInstagramWebView = () => {
  const ua = navigator.userAgent || navigator.vendor || window.opera;
  return /Instagram/.test(ua);
};

export default getMobileOperatingSystem;
// Link of the code: https://stackoverflow.com/a/21742107
