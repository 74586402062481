export const pizzaSizes = {
  INDIVIDUAL: 'individual',
  REGULAR: 'regular',
  MEDIUM: 'medium',
  FAMILY: 'family',
  EXTRA_LARGE: 'extra_large'
};

export const pizzaCrusts = {
  ORIGINAL: 'original',
  TRADITIONAL: 'traditional',
  THIN: 'thin',
  STUFFED: 'stuffed',
  PARMESAN: 'parmesan',
  PEPPERONI: 'pepperoni'
};

export const restrictions = {
  [pizzaSizes.MEDIUM]: [
    pizzaCrusts.TRADITIONAL,
    pizzaCrusts.THIN,
    pizzaCrusts.PARMESAN,
    pizzaCrusts.PEPPERONI
  ],
  [pizzaSizes.FAMILY]: [
    pizzaCrusts.TRADITIONAL,
    pizzaCrusts.THIN,
    pizzaCrusts.STUFFED,
    pizzaCrusts.PARMESAN,
    pizzaCrusts.PEPPERONI
  ],
  [pizzaSizes.EXTRA_LARGE]: [pizzaCrusts.TRADITIONAL]
};

export const BYOP_ICON = 'icon-make-pizza text-caribbean-green';

export const productTypes = {
  PIZZA: 'Pizza',
  PIZZA_VARIANT: 'PizzaVariant',
  COMPLEMENT: 'Complement',
  COMPLEMENT_VARIANT: 'ComplementVariant'
};

export const ALL_PIZZA_KINDS = {
  PIZZA: 'pizza',
  PIZZA_PREMIUM: 'premium',
  PIZZA_SPECIALTY: 'specialty'
};

export const pizzaCategories = {
  ...ALL_PIZZA_KINDS,
  MAKE_YOUR_PIZZA: 'byop',
  BY_HALVES: 'by_halves'
};

export const buildPizzaSections = {
  SIZE: 'size',
  CRUST: 'crust',
  MEAT: 'meat',
  VEGETABLE: 'vegetable',
  EXTRA_CHEESE: 'extra_cheese',
  BASE_SAUCE: 'base_sauce',
  BASE_CHEESE: 'base_cheese',
  SHOT: 'shots',
  EXTRA_SAUCE: 'extra_sauce',
  INGREDIENTS: 'ingredients',
  PREMIUM: 'premium',
  NOT_INGREDIENT: 'not_ingredient',
  SIZE_CRUST: 'size_crust'
};

export const PICTURE_TYPES = {
  photo: 'photo',
  photoApp: 'photo_app',
  icon: 'icon',
  pizza: 'pizza_edit',
  photoDelivery: 'delivery_photo',
  photoDeliveryApp: 'delivery_photo_app'
};

export const PHOTO_APP = 'photo_app';
