import i18next from 'i18next';

import { LANGUAGE_KEYS } from '~constants/languages';

i18next.addResources(LANGUAGE_KEYS.CHILE, 'BannerPapaPoints', {
  useThem: '¡Úsalos!',
  join: '¡Únete!',
  youHave: '¡Úsalos aquí!',
  accumulate: 'Gana y canjea',
  description:
    'Inicia sesión o regístrate para acumular Papa Puntos y canjearlos por productos irresistibles',
  pj: 'Papa',
  points: 'Puntos'
});

i18next.addResources(LANGUAGE_KEYS.PORTUGAL, 'BannerPapaPoints', {
  useThem: 'Usa-os!',
  join: 'Aderir',
  youHave: 'Acumulaste',
  accumulate: 'Ganha e troca',
  description: 'Acede ou cria a tua conta para acumular Papa Pontos e trocá-los por produtos irresistíveis',
  points: 'Pontos'
});

i18next.addResources(LANGUAGE_KEYS.SPAIN, 'BannerPapaPoints', {
  accumulate: 'Gana y canjea',
  join: '¡Unirme!',
  youHave: 'Has acumulado'
});
