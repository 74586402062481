import i18next from 'i18next';

import { CATEGORIES } from './constants';

i18next.addResources('es_CL', 'Ingredients', {
  pageTitle: "▷ Ingredientes de nuestras Pizzas | Papa John's Chile",
  pageDescription:
    "▷ Los mejores ingredientes de Pizzas Papa John's. ☆ La calidad de lo natural y el sabor de lo casero en tu plato. ¡Descúbrelos!",
  title1: 'Ingredientes de nuestras Pizzas',
  [CATEGORIES.MEAT]: 'Carnes',
  [CATEGORIES.VEGETABLE]: 'Vegetales',
  [CATEGORIES.EXTRA_CHEESE]: 'Quesos especiales',
  [CATEGORIES.SHOT]: 'Shots',
  [CATEGORIES.BASE]: 'Bases',
  [CATEGORIES.PREMIUM]: 'Premium',
  helper: 'Elige hasta {{amount}} {{category}} gratis'
});

// UPDATE THIS FOR SPAIN
i18next.addResources('es_ES', 'Ingredients', {
  pageTitle: '▷ Ingredientes de nuestras Pizzas | Papa Johns España',
  pageDescription:
    'La base de nuestra pizza son los mejores ingredientes. Descubre la diferencia que hacen mejores ingredientes.',
  keywords: 'Ingredientes pizza',
  title1: 'Ingredientes de nuestras Pizzas',
  [CATEGORIES.MEAT]: 'Carnes',
  [CATEGORIES.VEGETABLE]: 'Vegetales',
  [CATEGORIES.EXTRA_CHEESE]: 'Quesos especiales',
  [CATEGORIES.SHOT]: 'Shots',
  [CATEGORIES.BASE]: 'Bases',
  [CATEGORIES.PREMIUM]: 'Premium'
});

i18next.addResources('pt_PT', 'Ingredients', {
  pageTitle: "Nossos ingredientes | Papa John's Portugal",
  pageDescription:
    'A base da nossa pizza são os melhores ingredientes. Descobre a diferença que fazem os melhores ingredientes.',
  title1: 'Ingredientes das nossas pizzas',
  [CATEGORIES.MEAT]: 'Carnes',
  [CATEGORIES.VEGETABLE]: 'Legumes',
  [CATEGORIES.EXTRA_CHEESE]: 'Queijos especiais',
  [CATEGORIES.SHOT]: 'Shots',
  [CATEGORIES.BASE]: 'Bases',
  [CATEGORIES.PREMIUM]: 'Premium',
  helper: 'Escolhe até {{amount}} {{category}} grátis'
});

i18next.addResources('es_PA', 'Ingredients', {
  pageTitle: "Nuestros ingredientes | Papa John's Panama",
  [CATEGORIES.PREMIUM]: 'Premium'
});

i18next.addResources('es_CR', 'Ingredients', {
  pageTitle: "▷ Ingredientes de nuestras Pizzas | Papa John's Costa Rica",
  pageDescription:
    "▷ Los mejores ingredientes de Pizzas Papa John's. ☆ La calidad de lo natural y el sabor de lo casero en tu plato. ¡Descúbrelos!",
  keywords: 'Ingredientes pizza',
  [CATEGORIES.PREMIUM]: 'Premium'
});

i18next.addResources('es_GT', 'Ingredients', {
  pageTitle: "Nuestros ingredientes | Papa John's Guatemala",
  [CATEGORIES.PREMIUM]: 'Premium'
});
