import i18next from 'i18next';

i18next.addResources('es_CL', 'Order', {
  pagePath: '/pedidos',
  pageTitle: "Estado e historial de tus pedidos | Papa John's Chile",
  pageDescription:
    "Encuentra el seguimiento de tu pedido y todo el historial de tus pedidos anteriores de Papa John's Chile.",
  currentOrder: 'Pedido actual',
  pastOrder: 'Historial de pedidos',
  orderReceive: 'Hemos recibido tu pedido',
  orderPrepared: 'Estamos preparando tu pedido',
  orderOven: 'Tu pedido está en el horno',
  orderDelivery: 'Tu pedido está en camino',
  orderInfoPickup: 'Estará listo para retiro entre las {{time1}} y {{time2}} aprox.',
  orderInfoDelivery: 'Estará llegando entre las {{time1}} y {{time2}} aprox.',
  orderInfoScheduled: 'Estará listo el {{day}} - {{rangeTime}}',
  orderPickup: 'Tu pedido está listo para ser retirado',
  orderPending: 'Pedido pendiente de pago online',
  orderCanceled: 'Tu pedido fue cancelado',
  orderScheduled: 'Tu pedido ha sido programado',
  paymentFailed: 'El pago online falló',
  orderLater: 'Orden pendiente por generar',
  withoutOrder: 'No tienes pedidos activos',
  buttonGoMenu: 'Ir al menú',
  missingItems:
    'Los siguientes elementos no están disponibles para la tienda o el método de despacho seleccionado:',
  receive: 'Recibido',
  prepared: 'Preparación',
  oven: 'Horno',
  delivery: 'En camino',
  pickUp: 'Para retiro en local',
  pickUpPapaCar: 'Para delivery a tu auto',
  detail: 'Detalle',
  detailLocal: 'Datos del local',
  subTotal: 'Subtotal: {{quantity}}',
  total: 'Total: {{quantity}}',
  messageErrorOnlinePayment: 'Hubo un error al generar el pago mediante {{type}}',
  questionOnlinePayment: 'No se ha podido procesar el pago',
  buttonTry: 'Volver a intentar',
  iconError: 'Ícono de error',
  noShoppingCart: 'Hubo un error cargando los datos del carrito',
  noPastOrders: 'No encontramos pedidos',
  validateMessage: 'Comprobando el pago',
  requestError: '¡Lo sentimos! Ha ocurrido un error...',
  retry: 'Reintentar',
  tip: 'Propina: {{quantity}}',
  deliveryPrice: 'Delivery: {{quantity}}',
  freeDelivery: 'Gratis',
  scheduled: 'Programado',
  canceledOrder: '¡Lo sentimos! Ha ocurrido un error y tu pedido ha tenido que ser pasado a cancelado',
  orderNumber: 'Orden Nº',
  giftCard: 'Gift Card aplicada: {{quantity}}',
  withoutOrderDescription:
    'Selecciona tus favoritos del menú, agrégalos al carrito y procede al pago para completar tu compra.'
});

i18next.addResources('es_CR', 'Order', {
  pageTitle: "Estado e historial de tus pedidos | Papa John's Costa Rica",
  pickUp: 'Para retiro en restaurante',
  withoutOrder:
    'Tus pedidos han sido completados. \n Si estás esperando un pedido y no ves el estado de la orden, \n por favor espera unos minutos ya que debe estar por llegar. \n Puedes revisar el Historial para ver pedidos pasados'
});

i18next.addResources('es_PA', 'Order', {
  pageTitle: "Estado e historial de tus pedidos | Papa John's Panama",
  pickUp: 'Para retiro en restaurante',
  withoutOrder:
    'Tus pedidos han sido completados. \n Si estás esperando un pedido y no ves el estado de la orden, \n por favor espera unos minutos ya que debe estar por llegar. \n Puedes revisar el Historial para ver pedidos pasados'
});

i18next.addResources('es_ES', 'Order', {
  pageTitle: "Estado e historial de tus pedidos | Papa John's España",
  pageDescription:
    "Encuentra el seguimiento de tu pedido y todo el historial de tus pedidos anteriores de Papa John's España.",
  orderPickup: 'Tu pedido está listo para recoger',
  paymentFailed: 'El pago online ha fallado',
  oven: 'En el horno',
  delivery: 'En reparto',
  pickUp: 'Pedido preparado',
  currentOrder: 'Pedido en curso',
  withoutOrderDescription:
    '¡Ups! Ahora mismo no tienes ningún pedido en el horno, pero estamos deseando prepáratelo'
});

i18next.addResources('pt_PT', 'Order', {
  pagePath: '/pedido',
  pageTitle: "Encontra o historial do teu pedido | Papa John's Portugal",
  pageDescription: "Encontra o historial do teu pedido e pedidos anteriores de Papa John's Portugal.",
  currentOrder: 'Encomenda atual',
  pastOrder: 'Histórico de encomendas',
  orderReceive: 'Recebemos o teu pedido',
  orderPrepared: 'Estamos a preparar o teu pedido',
  orderOven: 'O teu pedido está no forno',
  orderDelivery: 'O teu pedido está a caminho',
  orderInfoPickup: 'O teu pedido está a tua espera para ser levantado na loja {{time1}} y {{time2}} aprox.',
  orderInfoDelivery: 'Chegará entre {{time1}} y {{time2}} aprox.',
  orderPickup: 'Seu pedido está pronto para pegar',
  orderPending: 'Pedido de pagamento pendente online',
  orderCanceled: 'O seu pedido foi cancelado',
  paymentFailed: 'O pagamento on-line falhou',
  orderLater: 'Ordem pendente para gerar.',
  withoutOrder: 'Não tem pedidos ativos',
  buttonGoMenu: 'Vá para o menu',
  receive: 'Recebido',
  prepared: 'Preparação',
  oven: 'No forno',
  delivery: 'Em distribuiçao',
  pickUp: 'Para recolher',
  detail: 'Detalhe',
  detailLocal: 'Dados do Local',
  subTotal: 'Subtotal: {{quantity}}',
  total: 'Total: {{quantity}}',
  messageErrorOnlinePayment: 'Ocorreu um erro ao gerar pagamento por meio de {{type}}',
  questionOnlinePayment: 'O pagamento não pôde ser processado',
  buttonTry: 'Tente novamente',
  iconError: 'Ícone de erro',
  noShoppingCart: 'Ocorreu um erro ao carregar os dados do carrinho',
  noPastOrders: 'Nós não podemos encontrar pedidos',
  validateMessage: 'Verificando o pagamento',
  requestError: 'Sentimos muito! Ocorreu um erro...',
  retry: 'Tente novamente',
  tip: 'Gorjeta: {{quantity}}',
  freeDelivery: 'Livre',
  scheduled: 'Agendado',
  orderScheduled: 'O teu pedido foi agendado',
  orderInfoScheduled: 'Estará pronto a {{day}} - {{rangeTime}}',
  canceledOrder: 'Pedimos desculpa, mas ocorreu um erro e o teu pedido foi cancelado.',
  orderNumber: 'Ordem Nº',
  withoutOrderDescription:
    'Ups! Não tens nenhuma encomenda no forno neste momento, mas estamos desejosos de a preparar para ti.'
});

i18next.addResources('es_GT', 'Order', {
  pageTitle: "Estado e historial de tus pedidos | Papa John's Guatemala",
  withoutOrder:
    'Tus pedidos han sido completados. \n Si estás esperando un pedido y no ves el estado de la orden, \n por favor espera unos minutos ya que debe estar por llegar. \n Puedes revisar el Historial para ver pedidos pasados'
});
