/* eslint-disable consistent-return */
/* eslint-disable no-console */
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { t } from 'i18next';

import * as serviceWorkerRegistration from '../../../../../serviceWorker';

import LocalStorageService from '~services/LocalStorageService';
import { shoppingCartSelector } from '~screens/Dashboard/screens/Home/redux/selectors';
import { EVENT, sendGTMEvent, eventCategory, eventAction } from '~utils/analytics';
import { isInstagramWebView } from '~utils/userAgent';

const useAuthToken = () => useMemo(() => LocalStorageService.getAuthToken(), []);

const requestNotificationPermission = async () => {
  if (typeof Notification !== 'undefined' && 'Notification' in window) {
    try {
      const { permission } = Notification;
      if (permission === 'default') {
        const granted = await Notification.requestPermission();
        if (granted !== 'granted') {
          console.error('Permiso de notificación denegado');
        }
      } else if (permission !== 'granted') {
        console.error('Permiso de notificación denegado');
      }
    } catch (error) {
      console.error('Error al solicitar permiso de notificación:', error);
    }
  } else {
    console.warn('Las notificaciones no están soportadas en este navegador');
  }
};

const useRegisterServiceWorker = () => {
  useEffect(() => {
    serviceWorkerRegistration.register({
      onUpdate: () => console.log('Service Worker Updated')
    });
  }, []);
};

const useSendCartData = (shoppingCart, authToken) => {
  useEffect(() => {
    if (isInstagramWebView()) {
      return;
    }

    const sendCartDataToSW = async () => {
      if (!shoppingCart || !('serviceWorker' in navigator)) {
        return;
      }

      const dataToBeSent = {
        data: {
          ...(typeof Notification !== 'undefined' && {
            title: t('abandonedCart:title'),
            body: t('abandonedCart:body', { quantity: shoppingCart.items.length }),
            icon: t('abandonedCart:icon'),
            url: t('abandonedCart:url')
          }),
          ...(authToken && { authToken }),
          baseURL: process.env.REACT_APP_CORE_HOST
        }
      };

      try {
        const registration = await navigator.serviceWorker.getRegistration();
        if (registration && registration.active) {
          const controller = registration.active;
          if (controller) {
            controller.postMessage({
              action: 'save-cart-data',
              shoppingCart
            });
            controller.postMessage({
              action: 'basic-data',
              ...dataToBeSent
            });
          } else {
            console.warn('No se encontró un controller activo.');
          }
        } else {
          console.warn('No hay un Service Worker registrado o activo.');
        }
      } catch (error) {
        console.error('Error al obtener el Service Worker:', error);
      }
    };

    sendCartDataToSW();
  }, [shoppingCart, authToken]);
};

const useAbandonedCartSync = shoppingCart => {
  useEffect(() => {
    if (isInstagramWebView()) {
      return;
    }

    const handleBeforeUnload = () => {
      if (shoppingCart) {
        sendGTMEvent(EVENT.eventPJ, {
          eventActionCategory: eventCategory.abandonedCart,
          action: eventAction.cartAbandonment
        });

        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.ready
            .then(registration => {
              if ('sync' in registration) {
                registration.sync.register('abandoned-event').catch(err => {
                  console.warn('Error al registrar el evento de sincronización:', err);
                });
              } else {
                console.warn('Sync API no está soportada en este navegador.');
              }
            })
            .catch(err => console.warn('Error con Service Worker:', err));
        }
      }
    };

    if (typeof Notification !== 'undefined' && Notification.permission === 'granted') {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [shoppingCart]);
};

const AbandonedCart = () => {
  const shoppingCart = useSelector(shoppingCartSelector);
  const authToken = useAuthToken();

  useRegisterServiceWorker();
  useSendCartData(shoppingCart, authToken);
  useAbandonedCartSync(shoppingCart);
  requestNotificationPermission();
  return null;
};

export default AbandonedCart;
