/* eslint-disable max-nested-callbacks */
import React from 'react';
import { createRoot } from 'react-dom/client';

import './config/i18n';
import './config/sentry';
import App from './app';
import './scss/application.scss';

const root = createRoot(document.getElementById('root'));

const render = () => {
  root.render(<App />, document.getElementById('root'));
};

// Render once
render(App);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./app', () => {
    render(App);
  });
}
