import i18next from 'i18next';

import { API_ERROR_CODES } from '~constants/apiErrors';
import { CURRENCY_SYMBOL } from '~constants/environment';

i18next.addResources('es_CL', 'App', {
  papaJohnsLogo: "Papa John's logo",
  pizza: 'Pizza',
  SEOdescription: 'Pizzería online para pedir a domicilio, a recoger o tomar en el local'
});

i18next.addResources('es_CL', 'Common', {
  cancel: 'Cancelar',
  confirm: 'Confirmar',
  next: 'Siguiente',
  finish: 'Finalizar',
  modify: 'Modificar',
  continue: 'Continuar',
  hello: '¡Hola, {{name}}!'
});

// TODO: Start changing error messages when used.
i18next.addResources('es_CL', 'APIErrors', {
  eundefined: 'Ha ocurrido un error, por favor intente nuevamente más tarde',
  e1001: 'Ha ocurrido un error con los campos ingresados, por favor revise los datos',
  e1002: API_ERROR_CODES.e1002,
  e1003: API_ERROR_CODES.e1003,
  e1004: API_ERROR_CODES.e1004,
  e1005: API_ERROR_CODES.e1005,
  e1006: API_ERROR_CODES.e1006,
  e1007: 'Ha ocurrido un error con el carrito.',
  e1008: API_ERROR_CODES.e1008,
  e1009: API_ERROR_CODES.e1009,
  e1010: '¡Ups! Tuvimos un problema para procesar tu pedido. Comunícate con la tienda para tomar tu orden',
  e1011: 'Algunos elementos presentes en la orden no están disponibles: {{items}}',
  e1012: API_ERROR_CODES.e1012,
  e1014: API_ERROR_CODES.e1014,
  e1019: API_ERROR_CODES.e1019,
  e1020: API_ERROR_CODES.e1020,
  e1021: API_ERROR_CODES.e1021,
  e1022: API_ERROR_CODES.e1022,
  e1024: 'La tienda está cerrada',
  e1025: API_ERROR_CODES.e1025,
  e1026: `El monto mínimo permitido para este método de despacho es de ${CURRENCY_SYMBOL}{{value}}`,
  e1027: API_ERROR_CODES.e1027,
  e1028: API_ERROR_CODES.e1028,
  e1029: API_ERROR_CODES.e1029,
  e1030: API_ERROR_CODES.e1030,
  e1031: API_ERROR_CODES.e1031,
  e1032: API_ERROR_CODES.e1032,
  e1033: API_ERROR_CODES.e1033,
  e1035: API_ERROR_CODES.e1035,
  e1036: API_ERROR_CODES.e1036,
  e1037: API_ERROR_CODES.e1037,
  e1038: 'No se puede crear la orden porque ya hay una en ejecución',
  e1040: 'Código no válido. Verifica que esté correcto e intente de nuevo.',
  e1044: `El monto máximo permitido para este método de envío es de ${CURRENCY_SYMBOL}{{value}}`,
  e1045: `Para usar tus Papa Puntos, el monto mínimo de la orden debe ser de ${CURRENCY_SYMBOL}{{value}}`,
  e1048: 'Este código solo aplica para Pizzas tamaño {{variant}}',
  e1048v2: 'Este cupón solo aplica para las categorías: {{categories}}',
  e1049: 'Este código expiró el {{expiry_date}}',
  e1050: 'El código ha alcanzado su límite de usos',
  e1051: 'Este código aún no está disponible',
  e1052: 'El código ha alcanzado su límite de usos por usuario',
  e1053: 'No se puede utilizar este código con el método de envío seleccionado',
  e1055: 'No estás habilitado para canjear este código',
  e1058: `Para utilizar este código tu pedido debe ser superior a ${CURRENCY_SYMBOL}{{value}}`,
  e1059: 'Este código solo puede canjearse desde {{platform}}',
  e1067: 'El número de cédula física del receptor no tiene el formato esperado',
  e1068: 'Este código solo es válido para tu primera compra',
  e1069: 'No puedes utilizar este código ya que pertenece a otro usuario',
  e1071: 'No es posible utilizar un código en un carrito que sólo contenga artículos en promoción',
  e1072: 'Al quitar el producto perderás el beneficio de crear tu combo, aumentando tu monto total.',
  e1075: 'Se ha alcanzado el límite de pedidos programados para esta tienda.',
  e1076: 'Este cupón aún no está disponible',
  e1077: 'Este cupón expiró el {{expiry_date}}',
  e1080: 'Agrega la promoción correspondiente para poder canjear este código',
  e1085: 'Este código no puede utilizarse para la compra de gift cards',
  e1087: 'Ha excedido el límite de bebidas alcohólicas',
  e1089:
    'Los siguientes productos no se encuentran en el menú actual y serán retirados de tu carrito:\n {{items}}',
  e1143: 'No se puede utilizar una Gift Card para comprar una Gift Card',
  e9999: 'Por favor intenta más tarde',
  paymentCardTaken: 'Este número de tarjeta ya ha sido registrado',
  e1156: 'Oops, algunos ingredientes están agotados, intenta con otro producto',
  e1159: 'Esta producto no está disponible para el método de despacho seleccionado'
});

i18next.addResources('es_CL', 'Maps', {
  noGoogleObject: 'Hubo un error inicializando el mapa.'
});

i18next.addResources('es_ES', 'App', {
  papaJohnsLogo: "Papa John's logo",
  pizza: 'Pizza'
});

i18next.addResources('es_ES', 'Common', {
  cancel: 'Cancelar',
  confirm: 'Confirmar',
  next: 'Siguiente',
  finish: 'Finalizar',
  modify: 'Modificar',
  continue: 'Continuar'
});

// TODO: Start changing error messages when used.
i18next.addResources('es_ES', 'APIErrors', {
  eundefined: 'Ha ocurrido un error, por favor intente nuevamente más tarde',
  e1001: 'Ha ocurrido un error con los campos ingresados, por favor revise los datos',
  e1002: API_ERROR_CODES.e1002,
  e1003: API_ERROR_CODES.e1003,
  e1004: API_ERROR_CODES.e1004,
  e1005: API_ERROR_CODES.e1005,
  e1006: API_ERROR_CODES.e1006,
  e1007: 'Ha ocurrido un error con el carrito',
  e1008: API_ERROR_CODES.e1008,
  e1009: API_ERROR_CODES.e1009,
  e1010: '¡Ups! Tuvimos un problema para procesar tu pedido. Comunícate con la tienda para tomar tu orden',
  e1011: 'Algunos elementos presentes en la orden no están disponibles: {{items}}',
  e1012: API_ERROR_CODES.e1012,
  e1014: API_ERROR_CODES.e1014,
  e1019: API_ERROR_CODES.e1019,
  e1020: API_ERROR_CODES.e1020,
  e1021: API_ERROR_CODES.e1021,
  e1022: API_ERROR_CODES.e1022,
  e1024:
    '¡Ups!  En estos momentos la tienda está cerrada, pero puedes programar tu pedido para que esté listo a la hora que necesites.',
  e1025: API_ERROR_CODES.e1025,
  e1026: `El monto mínimo permitido para este método de despacho es de {{value}}${CURRENCY_SYMBOL}`,
  e1027: API_ERROR_CODES.e1027,
  e1028: API_ERROR_CODES.e1028,
  e1029: API_ERROR_CODES.e1029,
  e1030: API_ERROR_CODES.e1030,
  e1031: API_ERROR_CODES.e1031,
  e1032: API_ERROR_CODES.e1032,
  e1033: API_ERROR_CODES.e1033,
  e1035: API_ERROR_CODES.e1035,
  e1036: API_ERROR_CODES.e1036,
  e1037: API_ERROR_CODES.e1037,
  e1038: 'No se puede crear la orden porque ya hay una en ejecución',
  e1044: `El monto máximo permitido para este método de envío es de ${CURRENCY_SYMBOL}{{value}}`,
  e1045: `Para usar tus Papa Puntos, el monto mínimo de la orden debe ser de ${CURRENCY_SYMBOL}{{value}}`,
  e1087: 'Ha excedido el limite de bebidas alcoholicas.',
  e1089:
    'Los siguientes productos no se encuentran en el menú actual y serán retirados de tu carrito:\n {{items}}',
  e1143: 'No se puede utilizar una Gift Card para comprar una Gift Card',
  e9999: 'Por favor intenta más tarde'
});

i18next.addResources('es_CR', 'APIErrors', {
  e9999: 'No hemos podido generar tu pedido',
  e1007: 'Hubo un error cargando los datos del carrito.'
});

i18next.addResources('es_ES', 'Maps', {
  noGoogleObject: 'Hubo un error inicializando el mapa.'
});

i18next.addResources('pt_PT', 'App', {
  papaJohnsLogo: "Papa John's logo",
  pizza: 'Pizza',
  SEOdescription: 'Pizzaria online para entrega ao domicílio, para retirada ou consumo no local'
});

i18next.addResources('pt_PT', 'Common', {
  cancel: 'Cancelar',
  confirm: 'Confirme',
  next: 'Próxima',
  finish: 'Concluir',
  modify: 'Modificar',
  continue: 'Continuar'
});

// TODO: Start changing error messages when used.
i18next.addResources('pt_PT', 'APIErrors', {
  eundefined: 'Ocorreu um erro, por favor tente novamente mais tarde',
  e1001: 'Ocorreu um erro com os campos introduzidos, por favor verifique os dados',
  e1002: API_ERROR_CODES.e1002,
  e1003: API_ERROR_CODES.e1003,
  e1004: API_ERROR_CODES.e1004,
  e1005: API_ERROR_CODES.e1005,
  e1006: API_ERROR_CODES.e1006,
  e1007: 'Ocorreu um erro com o carrinho',
  e1008: API_ERROR_CODES.e1008,
  e1009: API_ERROR_CODES.e1009,
  e1010:
    'Opa! Ocorreu um problema ao processar seu pedido. Entre em contato com a loja para fazer seu pedido',
  e1011: 'Alguns itens presentes no pedido não estão disponíveis: {{items}}',
  e1012: API_ERROR_CODES.e1012,
  e1014: API_ERROR_CODES.e1014,
  e1019: API_ERROR_CODES.e1019,
  e1020: API_ERROR_CODES.e1020,
  e1021: API_ERROR_CODES.e1021,
  e1022: API_ERROR_CODES.e1022,
  e1024:
    'Ups! Neste momento a loja está fechada, mas podes agendar a tua encomenda para que esteja pronta na hora que quiseres.',
  e1025: API_ERROR_CODES.e1025,
  e1026: `O montante mínimo permitido para este método de expedição é de {{value}}${CURRENCY_SYMBOL}`,
  e1027: API_ERROR_CODES.e1027,
  e1028: API_ERROR_CODES.e1028,
  e1029: API_ERROR_CODES.e1029,
  e1030: API_ERROR_CODES.e1030,
  e1031: API_ERROR_CODES.e1031,
  e1032: API_ERROR_CODES.e1032,
  e1033: API_ERROR_CODES.e1033,
  e1035: API_ERROR_CODES.e1035,
  e1036: API_ERROR_CODES.e1036,
  e1037: API_ERROR_CODES.e1037,
  e1038: 'A ordem não pode ser criada porque uma já está em execução',
  e1040: 'Código inválido. Verifica se está correto e tenta novamente.',
  e1044: `O montante máximo permitido para este método de envio é ${CURRENCY_SYMBOL}{{value}}`,
  e1045: `Para usar os seus Pontos Papa, o valor mínimo do pedido deve ser ${CURRENCY_SYMBOL}{{value}}`,
  e1048: 'Este código aplica-se apenas à categoria Pizzas, tamanho {{variant}}',
  e1048v2: 'Este código solo aplica para las categorias {{categories}}',
  e1049: 'Este código expirou em {{expiry_date}}',
  e1050: 'O código atingiu o seu limite de utilizações',
  e1051: 'Este código ainda não está disponível',
  e1052: 'O código atingiu o seu limite de uso por utilizador',
  e1053: 'Este código não pode ser usado com o método de envio selecionado',
  e1055: 'Não podes resgatar este código',
  e1058: `Para usar este código, o teu pedido deve ser superior a ${CURRENCY_SYMBOL}{{value}}`,
  e1059: 'Este código só pode ser resgatado a partir de {{platform}}',
  e1067: 'O número de identificação física do destinatário não possui o formato esperado',
  e1068: 'Este código é válido apenas para a tua primeira compra',
  e1069: 'Não podes usar este código pois pertence a outro utilizador',
  e1071: 'Não é possível usar um código num carrinho que contenha apenas itens promocionais',
  e1072: 'Ao remover o produto, você perderá o benefício de criar seu combo, aumentando seu valor total',
  e1075: 'O limite de pedidos agendados para esta loja foi atingido.',
  e1076: 'Este cupão ainda não está disponível',
  e1077: 'Este cupão expirou em {{expiry_date}}',
  e1080: 'Adicione a promoção correspondente para poder resgatar este código.',
  e1085: 'Este código não pode ser usado para comprar gift cards',
  e1089:
    'Os seguintes produtos não se encontram no menu actual e serão retirados do seu carrinho:\n {{items}}',
  e1143: 'Um Gift Card não pode ser usado para comprar um Gift Card',
  e1156: 'Ops, alguns ingredientes estão esgotados, tente outro produto',
  e9999: 'Por favor, tente novamente mais tarde',
  paymentCardTaken: 'Este número de cartão já foi registrado',
  e1159: 'Este produto não está disponível para o método de envio selecionado'
});

i18next.addResources('pt_PT', 'Maps', {
  noGoogleObject: 'Ocorreu um erro ao inicializar o mapa.'
});
// TODO: Start changing error messages when used.
