/* eslint-disable import/order */
/* eslint-disable import/no-extraneous-dependencies */
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';

import store from '../redux/store';

import { startIntercom } from '~services/IntercomService';
import { apiSetup } from '~config/api';
import Theme from '~config/MaterialUIStyles/Theme';
import { isGDPRCountry } from '~utils/cookieSettings';

import '../scss/application.scss';

import Routes from './components/Routes';
import ErrorBoundary from './components/ErrorBoundary/index';
import {
  loadAnalyticsScripts,
  renderHeadAnalyticsScripts,
  renderJsonLDSeoScript,
  renderPushAlert,
  weglotScript,
  hasAnalyticsConsent
} from './utils';

class App extends Component {
  componentDidMount() {
    apiSetup(store.dispatch);
    startIntercom();
    loadAnalyticsScripts();
  }

  render() {
    return (
      <ThemeProvider theme={Theme}>
        {(hasAnalyticsConsent() || !isGDPRCountry()) && renderHeadAnalyticsScripts()}
        {renderJsonLDSeoScript()}
        {renderPushAlert()}
        {weglotScript()}
        <Provider store={store}>
          <ErrorBoundary>
            <Routes />
          </ErrorBoundary>
        </Provider>
      </ThemeProvider>
    );
  }
}

App.defaultProps = {
  loading: false
};

export default App;
