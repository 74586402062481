import i18next from 'i18next';

i18next.addResourceBundle('es_CR', 'SinpeMovilModal', {
  lastStep: '¡Un último paso!',
  instruction1: {
    number: '1',
    text: 'Realizá tu pago de {{amount}} via SINPE Móvil al {{phoneSinpeMovil}}. El monto debe ser exacto.'
  },
  instruction2: {
    number: '2',
    text: 'Una vez que hayas realizado el pago, ingresá tu número de cédula tal como aparece en el documento, incluyendo los ceros. Recordá que debe tener exactamente 9 dígitos.'
  },
  instruction3: {
    number: '3',
    text: 'Da click en <strong>"Completar Pedido"</strong> y listo. Automáticamente buscaremos tu pago en el banco y lo asociaremos a tu orden.'
  },
  completeOrder: 'Completar Órden',
  identificationType: 'Tipo de identificación',
  clientDocumentId: 'Número de identificación',
  identificationTypeOptions: {
    'Cédula Identidad': 'Cédula Identidad',
    'Cédula Física': 'Cédula Física',
    'Cédula Jurídica': 'Cédula Jurídica',
    'Extranjero no residente': 'Extranjero no residente',
    Dimex: 'Dimex',
    Didi: 'Didi'
  },
  invalidFormatCI: 'El formato debe ser 0X-XXXX-XXXX.',
  obligatoryCI: 'La cédula es obligatoria.'
});

i18next.addResourceBundle('es_PA', 'SinpeMovilModal', {
  lastStep: '¡Un último paso!',
  instruction1: {
    number: '1',
    text: 'Realizá tu pago de {{amount}} via SINPE Móvil al {{phoneSinpeMovil}}. El monto debe ser exacto.'
  },
  instruction2: {
    number: '2',
    text: 'Una vez que hayas realizado el pago, ingresá tu número de cédula tal como aparece en el documento, incluyendo los ceros. Recordá que debe tener exactamente 9 dígitos.'
  },
  instruction3: {
    number: '3',
    text: 'Da click en <strong>"Completar Pedido"</strong> y listo. Automáticamente buscaremos tu pago en el banco y lo asociaremos a tu orden.'
  },
  completeOrder: 'Completar Órden',
  identificationType: 'Tipo de identificación',
  clientDocumentId: 'Número de identificación',
  identificationTypeOptions: {
    'Cédula Identidad': 'Cédula Identidad',
    'Cédula Física': 'Cédula Física',
    'Cédula Jurídica': 'Cédula Jurídica',
    'Extranjero no residente': 'Extranjero no residente',
    Dimex: 'Dimex',
    Didi: 'Didi'
  },
  invalidFormatCI: 'El formato debe ser 0X-XXXX-XXXX.',
  obligatoryCI: 'La cédula es obligatoria.'
});

i18next.addResourceBundle('es_CL', 'SinpeMovilModal', {
  lastStep: '¡Un último paso!',
  instruction1: {
    number: '1',
    text: 'Realizá tu pago de {{amount}} via SINPE Móvil al {{phoneSinpeMovil}}. El monto debe ser exacto.'
  },
  instruction2: {
    number: '2',
    text: 'Una vez que hayas realizado el pago, ingresá tu número de cédula tal como aparece en el documento, incluyendo los ceros. Recordá que debe tener exactamente 9 dígitos.'
  },
  instruction3: {
    number: '3',
    text: 'Da click en <strong>"Completar Pedido"</strong> y listo. Automáticamente buscaremos tu pago en el banco y lo asociaremos a tu orden.'
  },
  completeOrder: 'Completar Órden',
  identificationType: 'Tipo de identificación',
  clientDocumentId: 'Número de identificación',
  identificationTypeOptions: {
    'Cédula Identidad': 'Cédula Identidad',
    'Cédula Física': 'Cédula Física',
    'Cédula Jurídica': 'Cédula Jurídica',
    'Extranjero no residente': 'Extranjero no residente',
    Dimex: 'Dimex',
    Didi: 'Didi'
  },
  invalidFormatCI: 'El formato debe ser 0X-XXXX-XXXX.',
  obligatoryCI: 'La cédula es obligatoria.'
});
