import i18next from 'i18next';

i18next.addResources('es_CL', 'papaPointsInformation', {
  title:
    '¡POR CADA {{amountMoney}} EN PEDIDOS ONLINE ACUMULAS {{amountPapaPoints}} <span>PAPA</span><span>PUNTO</span>!',
  description:
    '<strong class="bold">¡No te quedes atrás!</strong> Muchos ya estamos canjeando Papa Puntos. Únete ahora y sé parte de la experiencia. 🍕✨',
  redeemForPizza: 'Canjea tus Papa Puntos por pizzas',
  redeemForComplements: 'Canjea tus Papa Puntos por complementos',
  amountMoney: '$1000',
  amountPapaPoints: '1',
  signInSingUp: 'Accede o crea tu cuenta'
});

i18next.addResources('es_CR', 'papaPointsInformation', {
  amountMoney: '₡1000'
});

i18next.addResources('es_PA', 'papaPointsInformation', {
  amountMoney: '$1',

  amountPapaPoints: '10'
});

i18next.addResources('es_GT', 'papaPointsInformation', {
  amountMoney: 'Q1'
});

i18next.addResources('es_ES', 'papaPointsInformation', {
  amountMoney: '1€',
  amountPapaPoints: '10'
});

i18next.addResources('pt_PT', 'papaPointsInformation', {
  title: 'Começa a acumular Papa Pontos',
  description: 'Adere já para ganhar Pontos que poderás trocar por deliciosas recompensas.',
  amountMoney: '1€',
  amountPapaPoints: '10',
  signInSingUp: 'Acede ou cria a tua conta'
});
