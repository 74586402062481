import { PICTURE_TYPES } from '~constants/products';
import { isMobile } from '~constants/sizes';

export const PICTURE_SIZES = { SM: 'small', MD: 'medium', LG: 'large', XL: 'extraLarge' };

const getPictureForCategory = (item, category) => item?.pictures?.find(pic => pic?.category === category);

export const getPictureUrlForCategory = (item, category) => getPictureForCategory(item, category)?.url;

export const getIcon = item => getPictureUrlForCategory(item, PICTURE_TYPES.icon);

export const getPhoto = item => getPictureUrlForCategory(item, PICTURE_TYPES.photo);

export const getPhotoThumbnail = (item, thumbnailSize = PICTURE_SIZES.LG, category = PICTURE_TYPES.photo) =>
  getPictureForCategory(item, category, thumbnailSize)?.thumbnails?.[thumbnailSize] || getPhoto(item);

export const getModalPhotoThumbnail = (item, category, thumbnailSize = PICTURE_SIZES.LG, fallback) => {
  const size = isMobile() ? PICTURE_SIZES.LG : thumbnailSize;

  return item?.pictures?.find(picture => picture.category === category)?.thumbnails[size] || fallback;
};

export const getVideoUrl = videos => videos?.[0]?.url;
